import React from 'react';
import TableIconList from "../../Components/Lists/Table-Icon-List";
import {CheckBox} from "../Inputs/CheckBox";

function Tbody({data, hasOperation = false, buttons, hasCheckbox = false}) {
    return (
        <tbody>
        {data.map((item, index) =>
            <tr key={index} className="text-capitalize">
                {item.map((val, index) =>
                    (index === 0) ?
                        (hasCheckbox) ?
                            <th key={index} className="bg-gray border-white">
                                <CheckBox/>
                            </th>
                            : <th key={index} className="bg-gray border-white">{val}</th>
                        : <td key={index} className="bg-gray border-white">{val}</td>
                )}
                {
                    hasOperation &&
                    <td key={index} className="bg-gray border-white">
                        <TableIconList buttons={buttons}/>
                    </td>
                }

            </tr>
        )}
        </tbody>
    );
}

export default Tbody;