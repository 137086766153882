import React, {Component} from 'react';
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import {TitleLeft} from "../../Components/Titles/Title-Left";
import {ContentForm} from "../../Templates/Content_Form";
import {RoundedBtn} from "../../Components/Buttons/Rounded-Btn";
import {CheckBox} from "../../Components/Inputs/CheckBox";
import MapFormFlexFill from "../../Helpers/Maps/Map-Form-Flex-Fill";
import SectionHeadBtn from "../../Components/Buttons/Section-Head-Btn";
import {ContentSectionFooter} from "../../Templates/Content_Section_Footer";

class BillOfExchange extends Component {
    onClick = (id) => {
        console.log(id);
    };
    render() {
        const buttons = [
            {
                icon: 'search-document',
                func: this.onClick
            },
            {
                icon: 'refresh',
                func: ''
            }
        ];
        const tHead = [
            'Receipt NO',
            'Reference NO',
            'Contract NO',
            'Total Amount',
            'Amount Received',
            'Remaining Amount',
            'Tenant',
            'Place Of Merit',
            'Date Of Merit',
            'Operations'
        ];
        const rows = [
            ['#', '#', '#', '#', '#', '#', '#', '#', '#']
        ];
        const form = [
            {
                tagType: 'input',
                inputType: 'date',
                placeHolder: 'From',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'input',
                inputType: 'date',
                placeHolder: 'To',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'input',
                inputType: 'number',
                placeHolder: 'Contract No',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'input',
                inputType: 'number',
                placeHolder: 'Receipt No',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Bonds Services',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Charged Fro Reservation',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            }
        ];
        return (
            <>
                <TitleLeft text={'Bills Management'}/>
                <ContentForm>
                    <MapFormFlexFill formInputs={form}/>
                    <RoundedBtn text={'Search'}/>
                    <div className="w-100 d-flex">
                        <CheckBox labelText={'Non-Relay To My Hotel Financial'}/>
                    </div>
                </ContentForm>
                <ContentSection>
                    <ContentSectionHead title={'Bills'}>
                        <SectionHeadBtn btnIcon={'ui-add'} btnText={'Add'}/>
                    </ContentSectionHead>
                    <ContentSectionTable>
                        <Table>
                            <Thead>
                            <TrThead data={tHead}/>
                            </Thead>
                            <Tbody data={rows} buttons={buttons} hasOperation={true}/>
                        </Table>
                        <ContentSectionFooter resultText={'Results 1-8 of 8'}/>
                        <RoundedBtn text={'exit'} isFormSectionBtn={true}/>
                    </ContentSectionTable>
                </ContentSection>
            </>
        );
    }
}

export default BillOfExchange;