import React, {Component} from 'react';
import {TitleCenter} from "../../Components/Titles/Title-Center";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import {ContentSectionForm} from "../../Templates/Content_Section_Form";
import {Input} from "../../Components/Inputs/Input";
import {RoundedBtn} from "../../Components/Buttons/Rounded-Btn";
import {CircleList} from "../../Components/Lists/Circle-List";
import {CircleTextBottom} from "../../Components/Links/Circle-Text-Bottom";

class ServiceRevenue extends Component {
    render() {
        const tHead = [
            'addition',
            'service name',
            'price',
            'quantity',
            'total'
        ];
        const rows = [
            ['#', '#', '#', '#', '#']
        ];
        const form = [
            {
                inputType: 'number',
                placeHolder: 'Total Price',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                inputType: 'number',
                placeHolder: 'Discount',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                inputType: 'number',
                placeHolder: 'Price After Salary',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                inputType: 'number',
                placeHolder: 'Final Price',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                inputType: 'number',
                placeHolder: 'Value Added',
                onChange: '',
                onFocus: '',
                onBlur: ''
            }
        ];
        const list = [
            {
                imgUrl: 'srv1',
                text: 'laundry'
            },
            {
                imgUrl: 'srv2',
                text: 'Cafe'
            },
            {
                imgUrl: 'srv3',
                text: 'Festival Halls'
            },
            {
                imgUrl: 'srv4',
                text: 'Delay Difference'
            },
            {
                imgUrl: 'srv5',
                text: 'Fines'
            },
        ]
        return (
            <>
                <TitleCenter icon={'ui-browser'} title={'Services'}/>
                    <CircleList listClass={'flex-column flex-lg-row'}>
                        {list.map((list, index) =>
                            <CircleTextBottom key={index} text={list.text} imgUrl={list.imgUrl}/>
                        )}
                    </CircleList>
                <ContentSection>
                    <ContentSectionHead title={'Service Details'}/>
                    <ContentSectionTable>
                        <Table>
                            <Thead>
                            <TrThead data={tHead}/>
                            </Thead>
                            <Tbody data={rows}/>
                        </Table>
                        <ContentSectionForm>
                            {form.map((elm, index) =>
                                <div key={index}
                                     className="col-xl-4 col-lg-6 col-12 align-items-center"
                                >
                                    <label className="text-left text-capitalize mb-2 w-100">
                                        {elm.placeHolder}
                                    </label>
                                    <Input bg={'bg-gray'} type={elm.inputType} placeHolder={elm.placeHolder}/>
                                </div>
                            )}
                            <div className="row no-gutters w-100 justify-content-center">
                                <div className="col-lg-4 col-md-12">
                                    <RoundedBtn text={'transfer to room'}/>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <RoundedBtn text={'transfer to service revenue'}/>
                                </div>
                            </div>
                        </ContentSectionForm>
                    </ContentSectionTable>
                </ContentSection>
            </>
        );
    }
}

export default ServiceRevenue;