import * as React from 'react';

export function TextArea({placeHolder, required, onChange, onBlur, onFocus, bg = 'bg-white', row}) {
    return (
        <div className="form-group d-flex align-items-center mb-3 col-lg-auto col-md-12 px-1 flex-fill">
            <textarea className={`${bg} my-0 rounded text-capitalize form-control`}
                      placeholder={placeHolder} required={required}
                      onChange={onChange} onBlur={onBlur} onFocus={onFocus}
                      rows={row}
            ></textarea>
        </div>
    );
};