import React, {Component} from 'react';
import {TitleLeft} from "../../Components/Titles/Title-Left";
import {Input} from "../../Components/Inputs/Input";
import Select from "../../Components/Inputs/Select";
import {CheckBox} from "../../Components/Inputs/CheckBox";
import {ContentForm} from "../../Templates/Content_Form";
import {RoundedBtn} from "../../Components/Buttons/Rounded-Btn";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import {ContentSectionFooter} from "../../Templates/Content_Section_Footer";
import CircleRoom from "../../Components/Lists/Circle-Room";
import SectionHeadBtn from "../../Components/Buttons/Section-Head-Btn";
import MapFormFlexFill from "../../Helpers/Maps/Map-Form-Flex-Fill";

class checkIn extends Component {
    render() {
        const dumDataRooms = [
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
            {title: 'room 100'},
        ];
        const form = [
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Choose room',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
            {
                tagType: 'input',
                inputType: 'text',
                placeHolder: 'Room Name',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'number of rooms',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Floor',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'room type',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
        ];
        return (
            <>
                <TitleLeft text={'advanced search'}/>
                <ContentForm>
                    <MapFormFlexFill formInputs={form}/>
                    <RoundedBtn text={'Search'}/>
                    <div className="w-100 d-flex">
                        <CheckBox labelText={'show companies'}/>
                        <CheckBox labelText={'show companies'}/>
                    </div>
                </ContentForm>
                <ContentSection>
                    <ContentSectionHead
                        title={'check in'}
                        titleHaveImg={true}
                        titleImg={'in'}
                    >
                        <SectionHeadBtn
                            btnIcon={'cloud-download'}
                            btnText={'export backup'}
                        />
                    </ContentSectionHead>
                    <ContentSectionTable>
                        <CircleRoom rooms={dumDataRooms}/>
                        <ContentSectionFooter resultText={'Results 1-8 of 8'}/>
                        <RoundedBtn text={'exit'} isFormSectionBtn={true} />
                    </ContentSectionTable>
                </ContentSection>
            </>
        );
    }
}

export default checkIn;