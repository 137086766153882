import React from 'react';
import './App.css';
import {Header} from './Components/Header/Header';
import {Main} from './Templates/Main';
import {BrowserRouter} from "react-router-dom";
import Auth from "./Auth/Auth";

function App() {
    return (
        <>
            <BrowserRouter>
                <Auth>
                    <Header/>
                    <Main/>
                </Auth>
            </BrowserRouter>
        </>
    );
}

export default App;
