import React from 'react';

function CircleRoom({rooms, haveIcons = false}) {
    return (
        <ul className="section-list-room nav justify-content-center">
            {
                rooms.map((room, index) =>
                    <li key={index} className="nav-item text-center mx-2 mb-3">
                        <a className="nav-link text-capitalize text-dark py-0" href="">
                            <div className="section-circle p-3 mx-auto rounded-pill bg-green">
                                <img className="h-100 w-100" src="assets/img/room-label.png"/>
                                <small className="d-block my-0 p-0 mx-auto text-white">{room.title}</small>
                            </div>
                            {haveIcons &&
                            <ul className="nav align-items-center p-0 m-0 justify-content-center text-secondary">
                                <a className="nav-link px-2 pt-2 pb-0"><i className="icofont-imac"></i></a>
                                <a className="nav-link px-2 pt-2 pb-0"><i className="icofont-options"></i></a>
                            </ul>
                            }
                        </a>
                    </li>
                )
            }

        </ul>
    );
}

export default CircleRoom;