import React from 'react';
import {RoundedLink} from "../../Components/Links/Rounded-Link";

function MapLinkRounded({links, justify, hasMarginTop = true}) {
    return (
        <ul className={`nav p-0 ${hasMarginTop && 'mt-3'} mb-0 text-center ${justify && `justify-content-${justify}`}`}>
            {
                links.map((link, index) =>
                    <RoundedLink key={index} text={link.text} color={link.color} path={link.path} click={link.click}/>
                )
            }
        </ul>
    );
}

export default MapLinkRounded;