import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import CardInputs from "../../Components/Cards/Card-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import TextNote from "../../Components/Texts/Text-Note";
import MapInputGrids from "../../Helpers/Maps/Map-Input-Grids";
import MapGridTwoInputs from "../../Helpers/Maps/Map-Grid-Two-Inputs";
import MapMultipleCheckbox from "../../Helpers/Maps/Map-Multiple-Checkbox";

function Messages() {
    const {state: type} = useContext(Localize);
    const sectionInputs = [
        {
            element: 'input',
            text: Language[type].theNameOfTheSMSSender,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].theNameOfTheADSender,
            type: 'text'
        }
    ];
    const inputs = [
        [
            {
                element: 'checkbox',
                text: Language[type].activate,
            }
        ],
        [
            {
                element: 'input',
                text: Language[type].roomNamesInMessages,
                type: 'text'
            }
        ],
        [
            [
                {
                    element: 'input',
                    text: Language[type].theName,
                    type: 'text'
                },
                {
                    element: 'input',
                    text: Language[type].cellPhone,
                    type: 'number'
                },
                {
                    element: 'textareaLabel',
                    text: Language[type].sentTo,
                },
            ],
            [
                {
                    text: Language[type].todayStartTime,
                    inputs: [
                        {
                            element: 'select',
                            options: [
                                {
                                    val: '',
                                    text: 'choose'
                                }
                            ]
                        },
                        {
                            element: 'select',
                            options: [
                                {
                                    val: '',
                                    text: 'choose'
                                }
                            ]
                        },
                    ]
                },
            ]
        ],
        [
            {
                element: 'checkbox',
                text: Language[type].activate,
            },
            {
                element: 'checkbox',
                text: Language[type].showRoomName,
            }
        ],
        [
            [
                {
                    element: 'textareaLabel',
                    text: Language[type].messageText,
                },
                {
                    element: 'multipleCheckboxes',
                    text: Language[type].addToTheMessage,
                    checkboxes: <MapMultipleCheckbox hasMarginBottom={true} inputs={[
                        {text: Language[type].contractNumber},
                        {text: Language[type].date},
                        {text: Language[type].theRoom},
                        {text: Language[type].amount},
                        {text: Language[type].dailyRent},
                    ]}/>
                },
            ],
            [
                {
                    element: 'checkbox',
                    text: Language[type].activate,
                }
            ]
        ],
        [
            [
                {
                    element: 'textareaLabel',
                    text: Language[type].messageText,
                },
                {
                    element: 'multipleCheckboxes',
                    text: Language[type].addToTheMessage,
                    checkboxes: <MapMultipleCheckbox hasMarginBottom={true} inputs={[
                        {text: Language[type].contractNumber},
                        {text: Language[type].date},
                        {text: Language[type].theRoom},
                        {text: Language[type].amount},
                        {text: Language[type].dailyRent},
                    ]}/>
                },
            ],
            [
                {
                    element: 'checkbox',
                    text: Language[type].activate,
                }
            ]
        ],
        [
            [
                {
                    element: 'textareaLabel',
                    text: Language[type].messageText,
                },
                {
                    element: 'multipleCheckboxes',
                    text: Language[type].addToTheMessage,
                    checkboxes: <MapMultipleCheckbox hasMarginBottom={true} inputs={[
                        {text: Language[type].contractNumber},
                        {text: Language[type].date},
                        {text: Language[type].theRoom},
                        {text: Language[type].amount},
                        {text: Language[type].dailyRent},
                    ]}/>
                },
            ],
            [
                {
                    element: 'checkbox',
                    text: Language[type].activate,
                }
            ]
        ],
        [
            [
                {
                    element: 'input',
                    text: Language[type].theName,
                    type: 'text'
                },
                {
                    element: 'input',
                    text: Language[type].cellPhone,
                    type: 'number'
                },
                {
                    element: 'textareaLabel',
                    text: Language[type].sentTo,
                },
                {
                    element: 'multipleCheckboxes',
                    text: Language[type].addToTheMessage,
                    checkboxes: <MapMultipleCheckbox hasMarginBottom={true} inputs={[
                        {text: Language[type].contractNumber},
                        {text: Language[type].date},
                        {text: Language[type].theRoom},
                        {text: Language[type].amount},
                        {text: Language[type].dailyRent},
                    ]}/>
                },
            ],
            [
                {
                    element: 'checkbox',
                    text: Language[type].activate,
                },
                {
                    element: 'checkbox',
                    text: Language[type].showRoomName,
                }
            ],
        ],
        [
            [
                {
                    element: 'input',
                    text: Language[type].theName,
                    type: 'text'
                },
                {
                    element: 'input',
                    text: Language[type].cellPhone,
                    type: 'number'
                },
                {
                    element: 'textareaLabel',
                    text: Language[type].sentTo,
                },
                {
                    element: 'multipleCheckboxes',
                    text: Language[type].addToTheMessage,
                    checkboxes: <MapMultipleCheckbox hasMarginBottom={true} inputs={[
                        {text: Language[type].contractNumber},
                        {text: Language[type].date},
                        {text: Language[type].theRoom},
                        {text: Language[type].amount},
                        {text: Language[type].dailyRent},
                    ]}/>
                },
            ],
            [
                {
                    element: 'checkbox',
                    text: Language[type].activate,
                },
                {
                    element: 'checkbox',
                    text: Language[type].showRoomName,
                }
            ],
        ]
    ];
    const button = [
        [
            {
                text: Language[type].save,
                onClick: '',
            }
        ],
        [
            {
                text: Language[type].save,
                onClick: '',
            }
        ]
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].messages}/>
            <ContentSectionTable>
                <div className='row no-gutters align-items-center w-100'>
                    <div className='col-lg-auto'>
                        <p>{Language[type].yourCurrentBalance} <span className='px-4'>0</span></p>
                    </div>
                    <MapInputs inputs={sectionInputs} flexfill={true}/>
                </div>
                <TitleContent text={Language[type].generalList}/>
                <div className='row align-items-stretch'>
                    <CardInputs>
                        <TitleContent text={Language[type].rating}/>
                        <div className='row no-gutters align-items-baseline'>
                            <MapInputs inputs={inputs[0]}/>
                            <TextNote text={Language[type].messageSettingsErr} col={'auto'}/>
                            <TextNote text={Language[type].messageSettingsSuccess} alertColor={'success'}/>
                        </div>
                        <MapBtns btns={button[0]} flexFill={false} hasMarginTop={false} justify={'center'}/>
                    </CardInputs>
                    <CardInputs>
                        <TitleContent text={Language[type].roomsName}/>
                        <MapInputs inputs={inputs[1]}/>
                        <MapBtns btns={button[1]} flexFill={false} hasMarginTop={false} justify={'center'}/>
                    </CardInputs>
                    <CardInputs>
                        <TitleContent text={Language[type].managementMessages}/>
                        <MapInputs inputs={inputs[2][0]}/>
                        <MapGridTwoInputs inputs={inputs[2][1]} col={12}/>
                        <MapInputs inputs={inputs[3]}/>
                        <MapBtns btns={button[1]} flexFill={false} hasMarginTop={false} justify={'center'}/>
                    </CardInputs>
                    <CardInputs>
                        <TitleContent text={Language[type].reservationConfirmationMessages}/>
                        <MapInputs inputs={inputs[4][0]}/>
                        <MapInputs inputs={inputs[4][1]}/>
                        <MapBtns btns={button[1]} flexFill={false} hasMarginTop={false} justify={'center'}/>
                    </CardInputs>
                    <CardInputs>
                        <TitleContent text={Language[type].automaticMessagesToEnter}/>
                        <MapInputs inputs={inputs[5][0]}/>
                        <MapInputs inputs={inputs[5][1]}/>
                        <MapBtns btns={button[1]} flexFill={false} hasMarginTop={false} justify={'center'}/>
                    </CardInputs>
                    <CardInputs>
                        <TitleContent text={Language[type].automaticMessagesToExit}/>
                        <MapInputs inputs={inputs[6][0]}/>
                        <MapInputs inputs={inputs[6][1]}/>
                        <MapBtns btns={button[1]} flexFill={false} hasMarginTop={false} justify={'center'}/>
                    </CardInputs>
                    <CardInputs>
                        <TitleContent text={Language[type].theReservationLetterToTheOwner}/>
                        <MapInputs inputs={inputs[7][0]}/>
                        <MapInputs inputs={inputs[7][1]}/>
                        <MapBtns btns={button[1]} flexFill={false} hasMarginTop={false} justify={'center'}/>
                    </CardInputs>
                    <CardInputs>
                        <TitleContent text={Language[type].sendMessageWhenCreatingReceiptVoucher}/>
                        <MapInputs inputs={inputs[8][0]}/>
                        <MapInputs inputs={inputs[8][1]}/>
                        <MapBtns btns={button[1]} flexFill={false} hasMarginTop={false} justify={'center'}/>
                    </CardInputs>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default Messages;