import React, {PureComponent} from 'react';

class messagesAlert extends PureComponent {
    render() {
        return (
            <div>

            </div>
        );
    }
}


export default messagesAlert;