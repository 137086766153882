export const ar = {
        lang: 'English',
        signedIn: 'تم الدخول',
        signIn: 'الدخول',
        signOut: 'خروج',

// SideBar Links
        dashboard: 'الإحصائيات',
        roomManagement: 'الغرف',
        reservations: 'الحجوزات',
        frontOffices: 'المكاتب الأمامية',
        accountants: 'المحاسبون',
        bondsAndBills: 'Bonds And Bills',
        customers: 'العملاء',
        serviceRevenue: 'الخدمات',
        messages: 'الرسائل',
        reports: 'التقارير',
        advantages: 'المزايا',
        statistics: 'الاحصائيات',
        nightChecking: 'التدقيق الليلي',
        help: 'المساعدة',

// buttons
        exit: 'خروج',
        export: 'تصدير',
        exportAll: 'تصدير للجميع',
        exportBackup: 'Export Backup',
        search: 'بحث',
        addReservation: 'Add Reservation',
        addReservationGroup: 'Add Reservation Group',
        add: 'إضافه',
        addCustomers: 'Add Customers',
        transferToRoom: 'Transfer To Room',
        transferToServiceRevenue: 'transfer to service revenue',
        delete: 'حذف',
        edit: 'تعديل',
        print: 'طباعة',
        cancel: 'إلغاء',
        save: 'حفظ',
        done: 'تم',
        pricePackages: 'حزم الأسعار',
        priceDetails: 'تفاصيل السعر',
        rebuildSqlEntities: 'Rebuild Sql Entities',
        correctStatisticsForMonthlyYearlyNightProofingFiles: 'تصحيح احصائيات ملفات التدقيق الليلي شهري وسنوي',
        purgeJornalPostingsOlderThanYears: 'Purge N / A Jornal of Postings Older than 3 Years',
        purgeFoliosOlderThanYears: 'Purge Folios Older than 3 Years',
        fixCreditCardEncryption: 'Fix Credit Card Encryption',
        customerName: 'اسم العميل',
        country: 'الدولة',
        alternativeState: 'الدولة البديل',
        city: 'المدينة',
        alternativeCity: 'المدينة البديلة',
        engineCode: 'كود المحرك',
        dACode: 'كود DA',
        serverName: 'اسم السيرفر',
        theNameOfTheDatabase: 'اسم قاعدة البيانات',
        sQLServerUser: 'SQL Server User',
        sQLServerPWD: 'SQL Server PWD',
        maximumNumberOfUsers: 'أقصى عدد للمستخدمين',
        theMaximumNumberOfRooms: 'أقصى عدد للغرف',
        version: 'الإصدار',
        expirationDate: 'تاريخ إنتهاء الصلاحية',
        licenseCode: 'كود الرخصة',
        multiLanguage: 'متعدد اللغات',
        bOIFC: 'BO IFC',
        HospitalityLogo: 'لوجو ضيافة',
        experimental: 'تجريبي',
        hospitality: 'ضيافة',
        Suns: 'شموس',
        computers: 'Computers',
        devices: 'Devices',
        deviceGroups: 'Device Groups',
        importMissingAccountsWithDefaultSettings: 'Import Missing Accounts with Default Settings',
        notSubscriber: 'Not Subscriber',
        merge: 'دمج',
        timeDateSyncWithDevice: 'مزامنة الوقت والتاريخ مع الجهاز',

//Settings Module
        settings: 'الإعدادت',
        generalSettings: 'الإعدادات العامة',
        theRooms: 'الغرف',
        theSales: 'المبيعات',
        thePrices: 'الأسعار',
        accounting: 'المحاسبة',
        connectivity: 'الربط',
        protection: 'الحماية',
        theSystem: 'النظام',

        hotelDataSettings: 'إعدادات بيانات الفندق',
        contractTermsSettings: 'إعدادات شروط العقد',
        businessSourcesAndWebsites: 'مصادر الأعمال والمواقع',
        markets: 'الأسواق',
        regionsAndStates: 'المناطق والدول',
        reasonsForTheVisit: 'أسباب الزيارة',
        documentType: 'نوع الوثيقة',
        outOfOrder: 'خارج نطاق الطلب',
        typesOfReservations: 'أنواع الحجوزات',
        arrivalMethod: 'طريقة القدوم',

        hotelCodes:
            'رموز الفنادق',
        theProperties: 'خصائص',
        roomTypes: 'أنواع الغرف',
        furniture: 'الأثاث',
        connectingRoomsList: 'قائمة ربط الغرف',
        floorPlanDesign: 'تصميم مخطط الطوابق',

        weekend: 'نهاية الأسبوع',
        customerRating: 'تصنيف العملاء',
        reservationsSettings: 'إعدادات الحجوزات',

        seasonSeasons: 'مواسم الأسعار',
        priceCategories: 'فئات الأسعار',
        packages: 'الحزم',
        priceLists: 'قوائم الأسعار',
        theCurrency: 'العملة',

        accountsGroup: 'مجموعة الحسابات',
        accountSources: 'مصادر الحسابات',
        accounts: 'الحسابات',
        accountantsSettings: 'إعدادات المحاسبين',
        paymentMethods: 'طرق الدفع',
        taxAndFeeManagement: 'إدارة الضرائب والرسوم',
        serviceManagement: 'إدارة الخدمات',

        manageSubscriptions: 'إدارة الإشتراكات',
        connectTheFrontOffices: 'ربط المكاتب الأمامية',
        installTheBackOfficeLinkage: 'تنصيب الربط بالمكاتب الخلفية',
        doorLock: 'Door Lock',
        theRoomsAreInSuns: 'الغرف في شموس',

        sections: 'الأقسام',
        groups: 'المجموعات',
        userManagement: 'إدارة المستخدمين',

        theLicense: 'الرخصة',
        theAgreement: 'الإتفاقية',
        termsAndConditions: 'الشروط والأحكام',
        databaseMaintenance: 'صيانة قاعدة البيانات',
        aboutMyHotel: 'معلومات عن فندقي',

// Titles

        searchOptions: 'خيارات البحث',
        theDetails: 'التفاصيل',
        backToPriceLists: 'رجوع إلى قوائم الأسعار',
        sortAndSearch: 'فرز وبحث',
        backToGroups: 'رجوع إلى المجموعات',
        userLists: 'قوائم المستخدم',
        lists: 'القوائم',
        accountantTerms: 'صلاحيات المحاسب',
        reservationsTerms: 'صلاحيات الحجوزات',
        servicesTerms: 'صلاحيات الخدمات',
        backToTheMarket: 'رجوع إلى الأسواق',
        countries: 'الدول',
        areas: 'المناطق',
        businessSources: 'مصادر الأعمال',
        locations: 'المواقع',
        generalList: 'القائمة العامة',
        rating: 'التقييم',
        roomsName: 'اسم الغرف',
        managementMessages: 'رسائل الإدارة',
        reservationConfirmationMessages: 'رسائل تأكيد الحجز',
        automaticMessagesToEnter: 'الرسائل التلقائية للدخول',
        automaticMessagesToExit: 'الرسائل التلقائية للخروج',
        theReservationLetterToTheOwner: 'رسالة الحجز للمالك',
        sendMessageWhenCreatingReceiptVoucher: 'ارسال رسالة عند انشاء سند قبض',
        backToAccounts: 'رجوع إلى الحسابات',
// Inputs
        code: 'الرمز',
        theName: 'الاسم',
        alternativeName: 'الاسم البديل',
        theDefaultAccount: 'الحساب الافتراضي',
        price: 'السعر',
        theEquation: 'المعادلة',
        calculationMethod: 'طريقة الحساب',
        relayTheNextDay: 'ترحيل اليوم التالي',
        addPrice: 'إضافة السعر',
        season: 'موسم',
        startingDate: 'تاريخ البدء',
        ExpiryDate: 'تاريخ الإنتهاء',
        priority: 'الأولوية',
        priceCategory: 'فئة الأسعار',
        priceSymbol: 'رمز الأسعار',
        showActivePrices: 'إظهار الأسعار النشطة',
        virtualAccount: 'الحساب الأفتراضي',
        marketSymbol: 'رمز السوق',
        sourceCode: 'رمز المصدر',
        alternateDescription: 'الوصف البديل',
        theTextOfTheBill: 'نص الفاتورة',
        notes: 'ملاحظات',
        repetition: 'التكرار',
        beginningOfSale: 'بداية البيع',
        endOfSale: 'نهاية البيع',
        mealPlanner: 'مخطط الوجبات',
        breakfast: 'فطار',
        lunch: 'غداء',
        dinner: 'عشاء',
        ChooseAll: 'اختيار الكل',
        until: 'حتى',
        person: 'شخص',
        twoPersons: 'شخصان',
        threePerson: '3 أشخاص',
        fourPerson: '4 أشخاص',
        fivePerson: '5 أشخاص',
        internet: 'إنترنت',
        infant: 'رضيع',
        baby: 'طفل',
        addition: 'إضافة',
        fullPrice: 'السعر كامل',
        currencySymbol: 'رمز العملة',
        theNameOfTheCurrency: 'اسم العملة',
        theGroup: 'المجموعة',
        theFile: 'الملف',
        groupCode: 'رمز المجموعة',
        groupName: 'اسم المجموعة',
        alternativeGroupDescription: 'وصف المجموعة البديل',
        termsOfReference: 'صلاحيات',
        userName: 'اسم الدخول',
        fullName: 'الاسم الكامل',
        password: 'كلمة المرور',
        accountantNumber: 'رقم المحاسب',
        administration: 'الإدارة',
        dateOfBirth: 'تاريخ الميلاد',
        lockedTo: 'مقفل إلى',
        discountLimit: 'حد الخصم',
        dateOflastEntry: 'تاريخ آخر دخول',
        active: 'فعال',
        all: 'جميع',
        laundry: 'مغسلة',
        cafe: 'كافيه',
        festivelHalls: 'قاعة احتفالات',
        delayDifference: 'فرق تأخير',
        fines: 'غرامات',
        fine: 'غرامة',
        show: 'عرض',
        monday: 'الأثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة',
        saturday: 'السبت',
        sunday: 'الأحد',
        timeCheckIn: 'وقت الدخول',
        timeCheckOut: 'وقت الخروج',
        activate: 'تفعيل',
        hour: 'ساعة',
        pm: 'مساءً',
        am: 'صباح',
        gracePeriodForChangingRooms: 'فترة السماح لتغيير الغرفة',
        enteringTheRoomIsObligatoryInTheNewContract: 'دخول الغرفة اجباري في العقد الجديد',
        activateCheckoutTimeForMonthlyReservations: 'تفعيل وقت الخروج للحجوزات الشهرية',
        whenChangingTheRoomCalculateThePriceOfTheNewApartment: 'عند تغيير الغرفة احسب سعر الشقة الجديدة',
        hotelCode: 'رمز الفندق',
        theHotelName: 'اسم الفندق',
        areaCode: 'رمز المنطقة',
        areaName: 'اسم المنطقة',
        features: 'ميزات',
        alternativeLanguageFeatures: 'ميزات باللغة البديلة',
        roomType: 'نوع الغرفة',
        effectiveShowOnly: 'إظهار الفعال فقط',
        descriptionOne: 'الوصف 1',
        theFirstAlternativeDescription: 'الوصف البديل الأول',
        virtualAccounts: 'الحسابات الافتراضية',
        defaultPrice: 'السعر الافتراضي',
        insuranceAmount: 'مبلغ التأمين',
        theNumberOfRooms: 'عدد الغرف',
        access: 'الوصول',
        nightsRoomNumber: 'ليالي عدد الغرف',
        allowManualPosting: 'السماح بالترحيل اليدوي',
        transfer: 'نقل',
        roomService: 'خدمة الغرف',
        allowTransferFrom: 'السماح بالنقل من',
        allowTransferTo: 'السماح بالنقل إلى',
        telephoneGuide: 'دليل هاتف',
        theNewspaper: 'الصحيفة',
        qiblaDetermination: 'تحديد القبلة',
        foodMenu: 'قائمة الطعام',
        refrigerator: 'ثلاجة',
        hall: 'صالة',
        maximumNumberOfRooms: 'العدد الأقصى للغرف',
        certain: 'مؤكد',
        theYear: 'السنة',
        revenueBudget: 'ميزانية العائدات',
        budgetRooms: 'ميزانية الغرف',
        theRoom:'الغرفة',
        january: 'يناير',
        february: 'فبراير',
        march: 'مارس',
        april: 'أبريل',
        may: 'مايو',
        june: 'يونيو',
        july: 'يوليو',
        august: 'أغسطس',
        september: 'سبتمبر',
        october: 'أكتوبر',
        nov: 'نوفمبر',
        dec: 'ديسمبر',
        countryCode: 'رمز البلد',
        countryName: 'اسم البلد',
        StartFrom: 'ابدأ من',
        nationality: 'الجنسية',
        sunsCode: 'رمز الشموس',
        theCompanyName: 'اسم الشركة',
        theState: 'الدولة',
        district: 'المدينة',
        title: 'الحي',
        address: 'العنوان',
        companyPhone: 'هاتف الشركة',
        fax: 'فاكس',
        systemTiming: 'توقيت النظام',
        addedValueFivePercent: 'القيمة المضافة (5%)',
        currency: 'العملة',
        taxNumber: 'الرقم الضريبي',
        dateOfRegistration: 'تاريخ التسجيل',

        companyNameInEnglish: 'اسم الشركة بالانجليزي',
        eMail: 'البريد الإلكتروني',
        website: 'الموقع الإلكتروني',
        mailBox: 'صندوق البريد',
        postalCode: 'الرمز البريدي',
        TheTiming: 'التوقيت',
        theLogo: 'الشعار',
        theMap: 'الخريطة',
        time: 'الوقت',
        taxesIncluded: 'شامل الضريبة',
        marketCode: 'رمز السوق',
        marketClassification: 'تصنيف السوق',
        budget: 'ميزانية',
        marketRankings: 'تصنيفات السوق',
        descriptionTypeOfIdentity: 'وصف نوع الهوية',
        alternativeIdentityDescription: 'وصف الهوية البديل',
        visitCode: 'رمز الزيارة',
        descriptionOfTheAccessMethod: 'وصف طريقة الوصول',
        describeTheAlternativeMethodOfAccess: 'وصف طريقة الوصول البديلة',
        workSource: 'مصدر العمل',
        location: 'الموقع',
        details: 'التفاصيل',
        thePhone: 'الهاتف',
        yourCurrentBalance: 'رصيدك الحالي',
        theNameOfTheSMSSender: 'اسم مرسل SMS',
        theNameOfTheADSender: 'اسم مرسل AD',
        roomNamesInMessages: 'اسم الغرف في الرسائل',
        cellPhone: 'الجوال',
        sentTo: 'مرسول إلى',
        todayStartTime: 'وقت بداية اليوم',
        TransmissionTime: 'وقت الإرسال',
        showRoomName: 'إظهار اسم الغرف',
        theTextOfTheMessage: 'نص الرسالة',
        addToTheMessage: 'إضافة للرسالة',
        contractNumber: 'رقم العقد',
        dailyRent: 'الإيجار اليومي',
        deviceType: 'نوع الجهاز',
        deviceSet: 'مجموعة الجهاز',
        howToCommunicate: 'طريقة التواصل',
        wrench: 'تاريخ الصلاحية',
        systemIcon: 'مفتاح الربط',
        systemCode: 'رمز النظام',
        linkType: 'نوع الرابط',
        accountNo: 'رقم الحساب',
        messageText: 'نص الرسالة',
        account: 'حساب',
        registrationDate: 'تطبق على',
        appliedTo: 'تاريخ التسجيل',
        subjectToAddedValue: 'خاضع للقيمة المضافة',
        feeIncluded: 'شامل الرسوم',
        theLimit: 'الحد',
        theNumber: 'الرقم',
        theBeginningOfCriticism: 'بداية النقد',
        timeUsed: 'الوقت المستخدم',
        theBalance: 'الرصيد',
        theDifference: 'الفرق',
        start: 'البدء',
        close: 'الغلق',
        status: 'الحالة',
        dateOfLastPosting: 'تاريخ آخر ترحيل',
        todayTotal: 'إجمالي اليوم',
        totalMonth: 'إجمالي الشهر',
        totalYear: 'إجمالي العام',
        calculationFunctions: 'وظائف الحساب',
        theOldAccount: 'الحساب القديم',
        mergeWith: 'دمج مع',
        theType: 'النوع',
        clientName: 'اسم العميل',
// Tables
        number: 'رقم',
        category: 'الفئة',
        accountName: 'اسم الحساب',
        description: 'الوصف',
        required: 'Required',
        financial: 'Financial',
        referenceNo: 'Reference No',
        reservationStatus: 'Reservation Status',
        client: 'Client',
        duration: 'Duration',
        rent: 'Rent',
        amount: 'المبلغ',
        tax: 'Tax',
        total: 'Total',
        paid: 'Paid',
        creditor: 'Creditor',
        debit: 'Debit',
        operations: 'العمليات',
        receivedFrom: 'Received From',
        for: 'For',
        paymentMethod: 'Payment Method',
        date: 'التاريخ',
        received: 'Received',
        cashier: 'Cashier',
        totalAmount: 'Total Amount',
        amountReceived: 'Amount Received',
        remainingAmount: 'Remaining Amount',
        Tenant: 'Tenant',
        placeOfMerit: 'Place Of Merit',
        dateOfMerit: 'Date Of Merit',
        invoiceNO: 'Invoice NO',
        dateCreated: 'Date Created',
        paidTo: 'Paid To',
        exchangeItem: 'Exchange Item',
        evidenceType: 'Evidence Type',
        evidenceNumber: 'Evidence Number',
        versionRelease: 'Version Release',
        eMailAddress: 'E-Mail Address',
        serviceName: 'اسم الخدمة',
        quantity: 'Quantity',
        source: 'المصدر',
        case: 'الحالة',
        computerName: 'اسم الكمبيوتر',
        theAccount: 'الحساب',
        backOfficeAccount: 'حساب المكاتب الخلفية',
        text: 'النص',
        theRatio: 'النسبة',
        roomNumber: 'رقم الغرفة',
        costCenterOptional: 'مركز تكلفة/اختياري',
        officeAccount: 'حساب المكاتب',
        roomNumberInSuns: 'رقم الغرفة في شموس',
        arabicName: 'الاسم بالعربي',
        englishName: 'الاسم بالانجليزي',
        subsidiaryServices: 'الخدمات الفرعية',
        discount: 'خصم',
        choose: 'اختر',

// Text
        reservationSettingErrOne: 'هام جدا : ان تفعيلك لوقت الدخول سيترتب عليه حساب يوم اضافي سابق على العميل اذا تم عمل دخول له قبل هذا الوقت',
        reservationSettingErrTwo: 'هام جدا : ان تفعيلك لوقت الخروج سيترتب عليه حساب يوم اضافي على العميل اذا لم يتم عمل له تصفية قبل هذ الوقت',
        contractTermsSetting: 'تنبيه : زيادة شروط العقد اكثر من 16 سطر ربما يتسبب في طباعة عقد الايجار على ورقتين',
        messageSettingsErr: 'سيتم خصم رسالة واحدة',
        messageSettingsSuccess: 'تستخدم هذه الخدمة لمعرفة تقييم العملاء عن اداء الشقق (ترسل الرسالة في اليوم التالي الساعة 9 مساءً)',
    }
;