import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import TableIconList from "../../Components/Lists/Table-Icon-List";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function ServiceManagement() {
    const {state: type} = useContext(Localize);
    const tHead = [
        Language[type].serviceName,
        Language[type].englishName,
        Language[type].arabicName,
        Language[type].fine,
        Language[type].accountName,
        Language[type].accountantNumber,
        Language[type].subsidiaryServices,
        Language[type].choose,
        Language[type].edit,
        Language[type].delete,
        ''
    ];
    const rows = [
        ['#', '#', '#', '#', '#', '#', '#',
            <TableIconList buttons={[{func: '', icon: 'options'}]}/>,
            <TableIconList buttons={[{func: '', icon: 'ui-edit'}]}/>,
            <TableIconList buttons={[{func: '', icon: 'close-circled'}]}/>,
            <TableIconList buttons={[{func: '', icon: 'bill'}]}/>
        ]
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-primary',
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    const colInputs = [
        {
            element: 'input',
            text: Language[type].theName,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].englishName,
            type: 'text'
        },
        {
            element: 'checkbox',
            text: Language[type].fine,
            type: 'text'
        }
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].serviceManagement}/>
            <ContentSectionTable>
                <Table>
                    <Thead>
                        <TrThead data={tHead}/>
                    </Thead>
                    <Tbody data={rows}/>
                </Table>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <MapInputs inputs={colInputs}/>
                    </div>
                </div>
                <div className='d-flex justify-content-end w-100'>
                    <MapBtns btns={buttons} flexFill={false} hasMarginTop={false}/>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default ServiceManagement;