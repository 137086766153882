import * as React from 'react';
import {Route} from "react-router-dom";
import Dashboard from "../Modules/Dashboard/Dashboard";
import {RoomManagement} from "../Modules/Rooms-Management/Room-Management";
import Reservations from "../Modules/Reservations/Reservations";
import {BondsBills} from "../Modules/Bonds-Bills/Bonds-Bills";
import Customers from "../Modules/Customers/Customers";
import ServiceRevenue from "../Modules/Services-Revenue/Service-Revenue";
import {Messages} from "../Modules/Messages/Messages";
import {Reports} from "../Modules/Reports/Reports";
import {
    BONDS_BILLS_PATH,
    CUSTOMERS_PATH,
    DASHBOARD_PATH,
    MESSAGES_PATH,
    REPORTS_PATH,
    RESERVATION_PATH,
    ROOM_MANAGEMENT_PATH,
    SERVICES_PATH
} from "../Helpers/Link-Path";
import {useContext} from "react";
import Localize from "../Helpers/Context";

export function RouteSidebar() {
    const localize = '/' + useContext(Localize).state;
    return (
        <>
            <Route exact path={localize} component={Dashboard}/>
            <Route exact path={localize + ROOM_MANAGEMENT_PATH} component={RoomManagement}/>
            <Route path={localize + RESERVATION_PATH} component={Reservations}/>
            <Route exact path={localize + BONDS_BILLS_PATH} component={BondsBills}/>
            <Route path={localize + CUSTOMERS_PATH} component={Customers}/>
            <Route path={localize + SERVICES_PATH} component={ServiceRevenue}/>
            {/*<Route exact path={localize + MESSAGES_PATH} component={Messages}/>*/}
            <Route exact path={localize + REPORTS_PATH} component={Reports}/>
        </>
    );
};