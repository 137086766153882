import * as React from 'react';
import {Link, NavLink} from 'react-router-dom';

export function RoundedLink({color, text, path, click}) {
    return (
        <li className="nav-item mb-3 col-lg-auto col-md-12 px-1">
            <NavLink
                activeClassName={'bg-dark'}
                className={`nav-link text-capitalize text-white m-0 rounded-pill ${color}`}
                to={path} onClick={click}>{text}
            </NavLink>
        </li>
    );
};