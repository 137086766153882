import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function ExpiresLists() {
    const {state: type} = useContext(Localize);
    const inputs = [
        {
            element: 'checkbox',
            text: Language[type].theFile,
        },
        {
            element: 'checkbox',
            text: Language[type].settings,
        },
        {
            element: 'checkbox',
            text: Language[type].reservations,
        },
        {
            element: 'checkbox',
            text: Language[type].frontOffices,
        },
        {
            element: 'checkbox',
            text: Language[type].accountants,
        },
        {
            element: 'checkbox',
            text: Language[type].reports,
        },
        {
            element: 'checkbox',
            text: Language[type].advantages,
        },
        {
            element: 'checkbox',
            text: Language[type].statistics,
        },
        {
            element: 'checkbox',
            text: Language[type].nightChecking,
        },
        {
            element: 'checkbox',
            text: Language[type].help,
        },
    ];
    const buttons = [
        {
            text: Language[type].done,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        }
    ];
    return (
        <div className='row'>
            <div className='col-md-12'>
                <TitleContent text={Language[type].userLists}/>
                <MapInputs inputs={inputs}/>
                <MapBtns btns={buttons} flexFill={false} justify={'end'}/>
            </div>
        </div>
    );
}

export default ExpiresLists;