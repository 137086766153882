import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import {TitleLeft} from "../../Components/Titles/Title-Left";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import {RoundedLink} from "../../Components/Links/Rounded-Link";
import MapLinkRounded from "../../Helpers/Maps/Map-Link-Rounded";
import {SETTING_NESTED_PATH} from "../../Helpers/Link-Path";

function Markets() {
    const {state: type} = useContext(Localize);
    const colInputsLeftOne = [
        {
            element: 'input',
            text: Language[type].marketCode,
            type: 'text'
        }
    ];
    const colInputsRightOne = [
        {
            element: 'input',
            text: Language[type].marketCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].description,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].alternateDescription,
            type: 'text'
        },
        {
            element: 'select',
            text: Language[type].marketClassification,
            options: [
                {
                    val: '',
                    text: 'Choose'
                }
            ]
        },

    ];
    const tHeadOne = [
        Language[type].marketCode,
        Language[type].description
    ];
    const rowsOne = [
        ['#', "#"]
    ];
    const colInputsLeftTwo = [
        {
            element: 'input',
            text: Language[type].marketCode,
            type: 'text'
        }
    ];
    const colInputsRightTwo = [
        {
            element: 'input',
            text: Language[type].marketCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].description,
            type: 'number'
        }

    ];
    const tHeadTwo = [
        Language[type].code,
        Language[type].description
    ];
    const rowsTwo = [
        ['#', "#"]
    ];
    const btn = [
        {
            text: Language[type].budget,
            color: 'bg-dark',
            path: '/' + type + SETTING_NESTED_PATH.budget,
        }
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].delete,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].edit,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].print,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].markets}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].searchOptions}/>
                        <MapInputs inputs={colInputsLeftOne}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHeadOne}/>
                            </Thead>
                            <Tbody data={rowsOne}/>
                        </Table>
                        <MapLinkRounded links={btn}/>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputs inputs={colInputsRightOne}/>
                        <MapBtns btns={buttons}/>
                    </div>
                </div>
                <hr className='w-100'/>
                <TitleLeft text={Language[type].marketRankings}/>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].searchOptions}/>
                        <MapInputs inputs={colInputsLeftTwo}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHeadTwo}/>
                            </Thead>
                            <Tbody data={rowsTwo}/>
                        </Table>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputs inputs={colInputsRightTwo}/>
                        <MapBtns btns={buttons}/>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default Markets;