import React, {useContext} from 'react';
import TitleContent from "../../Components/Titles/Title-Content";
import {Language} from "../../Lang/Language";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import Localize from "../../Helpers/Context";
import MapInputs from "../../Helpers/Maps/Map-Inputs";

function DoorLockComputers() {
    const {state: type} = useContext(Localize);
    const inputs = [
        {
            element: 'input',
            text: Language[type].theName,
            type: 'text'
        }
    ];
    const tHead = [
        Language[type].computerName
    ];
    const rows = [
        ['#']
    ];
    return (
        <div className='row'>
            <div className='col-md-6'>
                <TitleContent text={Language[type].sortAndSearch}/>
                <MapInputs inputs={inputs}/>
                <Table>
                    <Thead>
                        <TrThead data={tHead}/>
                    </Thead>
                    <Tbody data={rows}/>
                </Table>
            </div>
        </div>
    );
}

export default DoorLockComputers;