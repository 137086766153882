import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import TitleContent from "../../Components/Titles/Title-Content";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import MapMultipleCheckbox from "../../Helpers/Maps/Map-Multiple-Checkbox";
import ExpireCheckboxes from "../../Helpers/Maps/Expire-Checkboxes";

function ExpiresServices() {
    const {state: type} = useContext(Localize);
    const inputs = [
        {
            parent: {
                text: Language[type].all,
            },
            children: [
                {text: Language[type].show},
                {text: Language[type].add},
                {text: Language[type].edit},
                {text: Language[type].delete},
                {text: Language[type].print}
            ]
        },
        {
            parent: {
                text: Language[type].laundry,
            },
            children: [
                {text: Language[type].show},
                {text: Language[type].add},
                {text: Language[type].edit},
                {text: Language[type].delete},
                {text: Language[type].print}
            ]
        },
        {
            parent: {
                text: Language[type].cafe,
            },
            children: [
                {text: Language[type].show},
                {text: Language[type].add},
                {text: Language[type].edit},
                {text: Language[type].delete},
                {text: Language[type].print}
            ]
        },
        {
            parent: {
                text: Language[type].festivelHalls,
            },
            children: [
                {text: Language[type].show},
                {text: Language[type].add},
                {text: Language[type].edit},
                {text: Language[type].delete},
                {text: Language[type].print}
            ]
        },
        {
            parent: {
                text: Language[type].delayDifference,
            },
            children: [
                {text: Language[type].show},
                {text: Language[type].add},
                {text: Language[type].edit},
                {text: Language[type].delete},
                {text: Language[type].print}
            ]
        },
        {
            parent: {
                text: Language[type].fines,
            },
            children: [
                {text: Language[type].show},
                {text: Language[type].add},
                {text: Language[type].edit},
                {text: Language[type].delete},
                {text: Language[type].print}
            ]
        },
    ];
    const buttons = [
        {
            text: Language[type].done,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        }
    ];
    return (
        <div className='row'>
            <div className='col-md-12'>
                <TitleContent text={Language[type].servicesTerms}/>
                <ExpireCheckboxes inputs={inputs}/>
                <MapBtns btns={buttons} flexFill={false} justify={'end'}/>
            </div>
        </div>
    );
}

export default ExpiresServices;