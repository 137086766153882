import * as React from 'react';
import {TitleCenter} from "../../Components/Titles/Title-Center";
import {CircleTextLeft} from "../../Components/Links/Circle-Text-Left";
import {useRouteMatch} from "react-router-dom";
import {ROOM_MANAGEMENT_NESTED_PATH, ROOM_MANAGEMENT_PATH} from "../../Helpers/Link-Path";
import {CircleList} from "../../Components/Lists/Circle-List";
import MapCircleLinkTextLeft from "../../Helpers/Maps/Map-Circle-Link-Text-Left";
import {useContext} from "react";
import Localize from "../../Helpers/Context";

export function RoomManagement() {
    const {state: type} = useContext(Localize);
    const list = [
        {
            color: 'bg-green',
            text: 'Check In',
            imgUrl: 'in',
            path: '/' + type + ROOM_MANAGEMENT_NESTED_PATH.checkIn
        },
        {
            color: 'bg-danger',
            text: 'Check Out',
            imgUrl: 'out',
            path: '/' + type + ROOM_MANAGEMENT_NESTED_PATH.checkOut
        },
        {
            color: 'bg-light-blue',
            text: 'Room Management',
            imgUrl: 'room',
            path: ROOM_MANAGEMENT_NESTED_PATH.rooms
        }
    ];
    return (
        <>
            <TitleCenter icon={'building-alt'} title={'Room Management'}/>
            <CircleList>
                <MapCircleLinkTextLeft list={list}/>
            </CircleList>
        </>
);
};