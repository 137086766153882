import * as React from 'react';
import DefaultList from "../Lists/Default-List";
import {DefaultLink} from "../Links/Default-Link";

export function SocialList() {
    const items = [
        'facebook',
        'twitter',
        'instagram',
        'whatsapp'
    ];
    return (
        <DefaultList listClass={'menu-social text-center d-none d-sm-flex'}>
            {
                items.map((item, index) =>
                    <DefaultLink
                        key={index}
                        iconClass={item}
                        itemClass={'justify-content-center bg-white mx-2 align-items-center d-flex'}
                    />
                )
            }
        </DefaultList>

    );
};