import React from 'react';

function TextNote({text, alertColor = 'danger', col = 12}) {
    return (
        <div className={`col-${col}`}>
            <p className={`w-100 text-${alertColor} text-left`}>{text}</p>
        </div>
    );
}

export default TextNote;