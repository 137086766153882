import React from 'react';
import {Language} from "../../Lang/Language";
import {useHistory} from "react-router-dom";

function BackLink({text}) {
    let history = useHistory();
    return (
        <div className='w-100 text-left'>
            <button onClick={() => history.goBack()} className='btn text-primary p-0 mb-3'
                    style={{textDecoration: 'underline'}}>
                {text}
            </button>
        </div>
    );
}

export default BackLink;