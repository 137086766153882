import * as React from 'react';

export function Input({type, placeHolder, required, onChange, onBlur, onFocus, bg = 'bg-white'}) {
    return (
        <div className="form-group d-flex align-items-center mb-3 col-lg col-md-12 px-1 ">
            <input className={`${bg} my-0 rounded-pill text-capitalize form-control`}
                   placeholder={placeHolder} type={type} required={required}
                   onChange={onChange} onBlur={onBlur} onFocus={onFocus}
            />
        </div>
    );
};