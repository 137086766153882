import React, {Component} from 'react';

class reportBills extends Component {
    render() {
        return (
            <div>

            </div>
        );
    }
}

export default reportBills;