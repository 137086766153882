import React from 'react';
import {Input} from "../../Components/Inputs/Input";
import Select from "../../Components/Inputs/Select";

function MapGridsTitle({titles}) {
    return (
        <div className='row align-items-center no-gutters'>
            <p className='col-lg-3 m-0'></p>
            <div className='col-lg-9 col-12'>
                <div className='row no-gutters align-items-center'>
                    {
                        titles.map((txt, index) =>
                            <small key={index} className='col-lg-6 mb-2 text-center text-secondary'>{txt}</small>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default MapGridsTitle;