import React, {PureComponent, useContext} from 'react';
import {Card} from "../../Components/Cards/Card";
import {TitleCard} from "../../Components/Titles/Title-Card";
import {Dots} from "../../Components/Links/Dots";
import {NotifyDashboard} from "../../Components/Links/Notify-Dashboard";
import Localize from "../../Helpers/Context";

function Dashboard() {
    return (
        <>
            <div className="col-xl-7 mb-3">
                <Card>
                    <TitleCard title={'The case of weekly apartments'} headClass={'text-center'}/>
                    <ul className="nav justify-content-center">
                        <Dots dotColor={'bg-danger'} text={'Empty'}/>
                        <Dots dotColor={'bg-green'} text={'Reserved'}/>
                    </ul>
                </Card>
            </div>
            <div className="col-xl-5 mb-3">
                <Card>
                    <TitleCard title={'The case of apartments'} headClass={'text-center'}/>
                    <ul className="nav justify-content-center">
                        <Dots dotColor={'bg-orange'} text={'Clean'}/>
                        <Dots dotColor={'bg-danger'} text={'Empty'}/>
                        <Dots dotColor={'bg-green'} text={'Reserved'}/>
                    </ul>
                    <ul className="nav flex-column w-100 mt-4">
                        <NotifyDashboard icon={'login'} text={'Check In'} color={'text-light-blue'} notifyNum={2}/>
                        <NotifyDashboard icon={'user-alt-1'} text={'The remaining guests'} color={'text-green'}
                                         notifyNum={2}/>
                        <NotifyDashboard icon={'logout'} text={'Check out'} color={'text-dark'} notifyNum={4}/>
                    </ul>
                </Card>
            </div>
        </>
    );
}


export default Dashboard;