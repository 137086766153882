import * as React from 'react';
import {Route} from 'react-router-dom';
import reportReceipt from "../Modules/Reports/Report-Receipt";
import exchangeItems from "../Modules/Reports/Exchange-Items";
import dailyTraffic from '../Modules/Reports/Daily-Traffic';
import monthlyTotal from '../Modules/Reports/Monthly-Total';
import clientReservations from '../Modules/Reports/Client-Reservations';
import fundMovement from '../Modules/Reports/Fund-Movement';
import bondsServices from '../Modules/Reports/Bonds-Services';
import openReservations from '../Modules/Reports/Open-Reservations';
import reportMessages from '../Modules/Reports/Report-Messages';
import changeRooms from '../Modules/Reports/Change-Rooms';
import rates from '../Modules/Reports/Rates';
import yearlyInMonths from '../Modules/Reports/Yearly-In-Months';
import taxesFess from '../Modules/Reports/Taxes-Fess';
import tourismAuthority from '../Modules/Reports/Tourism-Authority';
import reportBills from '../Modules/Reports/Report-Bills';
import staffStatistics from '../Modules/Reports/Staff-Statistics';
import {REPORTS_NESTED_PATH} from "../Helpers/Link-Path";

export function RouteReport() {

    return (
        <>
            <Route path={REPORTS_NESTED_PATH.fundMovement} component={fundMovement}/>
            <Route path={REPORTS_NESTED_PATH.exchangeItems} component={exchangeItems}/>
            <Route path={REPORTS_NESTED_PATH.trafficReport} component={dailyTraffic}/>
            <Route path={REPORTS_NESTED_PATH.monthlyTotal} component={monthlyTotal}/>
            <Route path={REPORTS_NESTED_PATH.clientReservations} component={clientReservations}/>
            <Route path={REPORTS_NESTED_PATH.receipt} component={reportReceipt}/>
            <Route path={REPORTS_NESTED_PATH.bondServices} component={bondsServices}/>
            <Route path={REPORTS_NESTED_PATH.openReservations} component={openReservations}/>
            <Route path={REPORTS_NESTED_PATH.messages} component={reportMessages}/>
            <Route path={REPORTS_NESTED_PATH.changeRooms} component={changeRooms}/>
            <Route path={REPORTS_NESTED_PATH.rates} component={rates}/>
            <Route path={REPORTS_NESTED_PATH.yearlyMonths} component={yearlyInMonths}/>
            <Route path={REPORTS_NESTED_PATH.taxesFees} component={taxesFess}/>
            <Route path={REPORTS_NESTED_PATH.tourismAuthority} component={tourismAuthority}/>
            <Route path={REPORTS_NESTED_PATH.bills} component={reportBills}/>
            <Route path={REPORTS_NESTED_PATH.staffStatistics} component={staffStatistics}/>
        </>
    );
};