import React from 'react';

function TableIconList({buttons}) {

    return (
        <ul className="nav p-0 m-0 text-secondary">
            {
                buttons.map((btn, index) =>
                    <button key={index} onClick={() => btn.func('test')}
                            className="nav-link btn rounded-pill px-2 py-0 m-0">
                        <i className={`icofont-${btn.icon}`}></i>
                    </button>
                )}
        </ul>
    );
}

export default TableIconList;