import * as React from 'react';

export function DefaultLink({iconClass, itemClass, anchorClass, text, onClick}) {

    return (
        <li className={`nav-item px-2 py-0 ${itemClass}`}>
            <a onClick={onClick}
               className={`nav-link text-white p-0 text-uppercase font-weight-bold d-flex align-items-center ${anchorClass}`}>
                {iconClass && <i className={`icofont-${iconClass}`}></i>}
                {text && <span className="px-2">{text}</span>}
            </a>
        </li>
    );
};