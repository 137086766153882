import React, {Component} from 'react';
import {TitleCenter} from "../../Components/Titles/Title-Center";

class Login extends Component {
    render() {
        return (
            <div className="row align-items-center justify-content-center h-100 no-gutters">
                <div className="col-lg-6 col-12 bg-white">
                    <div className="rounded w-100 p-5 border h-100">
                        <TitleCenter icon={'login'} title={'Sign In'}/>
                        <form className="w-100 mt-3">
                            <div
                                className="form-group d-flex align-items-center mb-3 col-lg-auto col-md-12 px-1 flex-fill">
                                <input className="my-0 rounded-pill text-capitalize form-control bg-gray"
                                       placeholder="name" type="text"/></div>
                            <div
                                className="form-group d-flex align-items-center mb-3 col-lg-auto col-md-12 px-1 flex-fill">
                                <input className="my-0 rounded-pill text-capitalize form-control bg-gray"
                                       placeholder="password" type="password"/></div>
                            <div
                                className="form-group d-flex align-items-center mb-3 col-lg-auto col-md-12 px-1 flex-fill">
                                <button
                                    className="btn text-white rounded-pill text-capitalize mx-auto bg-light-blue">
                                    Sign In
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;