import React from 'react';

function DefaultList({children, listClass}) {
    return (
        <ul className={`nav ${listClass}`}>
            {children}
        </ul>
    );
}

export default DefaultList;