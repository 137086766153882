import React from 'react';

function ThereNoData() {
    return (
        <div className="col-12 text-center">
            <img className="mx-auto h-25" src="assets/img/document.png"/>
            <h3 className="h3 w-100 text-center mt-3 mb-0 text-secondary">There is no data</h3>
        </div>
    );
}

export default ThereNoData;