import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class messagesSendMessage extends PureComponent {
    render() {
        return (
            <div>
                asdsadasd
            </div>
        );
    }
}


export default messagesSendMessage;