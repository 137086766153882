import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {SETTING_NESTED_PATH} from "../../Helpers/Link-Path";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import MapLinkRounded from "../../Helpers/Maps/Map-Link-Rounded";
import {Route, Switch} from "react-router-dom";
import BackLink from "../../Components/Buttons/Back-Link";
import ExpiresLists from "./Expires-Lists";
import ExpiresReservations from "./Expires-Reservations";
import ExpiresAccountants from "./Expires-Accountants";
import ExpiresServices from "./Expires-Services";
import MapGridTwoInputs from "../../Helpers/Maps/Map-Grid-Two-Inputs";

function Expires({match}) {
    const {state: type} = useContext(Localize);
    const inputs = [
        {
            text: Language[type].theGroup,
            inputs: [
                {
                    element: 'input',
                    text: 'A & G',
                    type: 'text'
                },
                {
                    element: 'input',
                    text: 'Administration',
                    type: 'text'
                }
            ]
        }
    ]
    const tabButtons = [
        {
            text: Language[type].lists,
            color: 'bg-secondary',
            path: '/' + type + SETTING_NESTED_PATH.groupsExpiresLists,
        },
        {
            text: Language[type].reservations,
            color: 'bg-secondary',
            path: '/' + type + SETTING_NESTED_PATH.groupsExpiresReservations,
        },
        {
            text: Language[type].accountants,
            color: 'bg-secondary',
            path: '/' + type + SETTING_NESTED_PATH.groupsExpiresAccountants,
        },
        {
            text: Language[type].serviceRevenue,
            color: 'bg-secondary',
            path: '/' + type + SETTING_NESTED_PATH.groupsExpiresServices,
        }
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].termsOfReference}/>
            <ContentSectionTable>
                <BackLink text={Language[type].backToGroups}/>
                <MapGridTwoInputs inputs={inputs} col={12}/>
                <MapLinkRounded links={tabButtons} justify={'center'} hasMarginTop={false}/>
                <Switch>
                    <Route exact path={match.url + '/lists'} component={ExpiresLists}/>
                    <Route path={match.url + '/reservations'} component={ExpiresReservations}/>
                    <Route path={match.url + '/accountants'} component={ExpiresAccountants}/>
                    <Route path={match.url + '/services'} component={ExpiresServices}/>
                </Switch>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default Expires;