import * as React from 'react';
import {Route} from 'react-router-dom';
import {Settings} from "../Modules/Settings/Settings";
import {SETTING_PATH} from "../Helpers/Link-Path";
import {useContext} from "react";
import Localize from "../Helpers/Context";

export function RouteHeader() {
    const localize = '/' + useContext(Localize).state;

    return (
        <>
            <Route exact path={localize + SETTING_PATH} component={Settings}/>
        </>
    );
};