import React, {useContext} from 'react';
import {Sidebar} from '../Components/Sidebar/Sidebar';
import {Content} from "./Content";

export function Main() {

    return (
        <main className="d-flex h-100 w-100">
            <Sidebar/>
            <Content/>
        </main>
    );
};