import React, {useContext, useEffect, useReducer, useState} from 'react';
import Localize, {ShowPopUp} from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {SETTING_NESTED_PATH} from "../../Helpers/Link-Path";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapLinkRounded from "../../Helpers/Maps/Map-Link-Rounded";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import {CheckBox} from "../../Components/Inputs/CheckBox";
import PopUp from "../../Components/Popups/PopUp";
import {ShowBool} from "../../Helpers/Reducer";

function Accounts() {
    const {state: type} = useContext(Localize);
    const [showPopUp, dispatch] = useReducer(ShowBool, false);
    const colInputsLeft = [
        {
            element: 'input',
            text: Language[type].accounts,
            type: 'text'
        }
    ];
    const onClick = () => {
        dispatch(!showPopUp);
    }
    const links = [
        {
            text: Language[type].merge,
            color: 'bg-success',
            path: '#',
            click: onClick
        },
        {
            text: Language[type].budget,
            color: 'bg-primary',
            path: '/' + type + SETTING_NESTED_PATH.accountsBudget
        }
    ];
    const colInputsRight = [
        [
            {
                element: 'checkbox',
                text: Language[type].active
            },
            {
                element: 'input',
                text: Language[type].accounts
            },
            {
                element: 'select',
                text: Language[type].descriptionOne,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'select',
                text: Language[type].alternateDescription,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'select',
                text: Language[type].accountsGroup,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'select',
                text: Language[type].source,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'input',
                text: Language[type].dateOfLastPosting,
                type: 'date'
            },
            {
                element: 'input',
                text: Language[type].todayTotal,
                type: 'number'
            },
            {
                element: 'input',
                text: Language[type].totalMonth,
                type: 'number'
            },
            {
                element: 'input',
                text: Language[type].totalYear,
                type: 'number'
            },
            {
                element: 'input',
                text: Language[type].theGroup,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].defaultPrice,
                type: 'number'
            },
            {
                element: 'select',
                text: 'default window',
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'select',
                text: Language[type].calculationFunctions,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'input',
                text: 'Def. arrangement',
                type: 'text'
            }
        ],
        [
            {
                element: 'checkbox',
                text: Language[type].allowManualPosting,
            },
            {
                element: 'checkbox',
                text: 'apply default billing',
            }
        ]
    ];
    const tHead = [
        Language[type].accounts,
        Language[type].description,
    ];
    const rows = [
        ['#', '#']
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].delete,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].edit,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].print,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    const tHeadTo = [
        '',
        Language[type].code,
        Language[type].description,
    ];
    const rowsTo = [
        [<CheckBox labelText={''}/>, '#', '#']
    ];
    const popUpEls = [
        [
            {
                element: 'input',
                text: Language[type].theAccount,
                type: 'text'
            },
            {
                element: 'select',
                text: Language[type].description,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            }
        ],
        [
            {
                element: 'select',
                text: Language[type].theAccount,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            }]
    ];
    const popUpBtns = [
        {
            text: Language[type].merge,
            onClick: '',
            color: 'bg-success'
        },
        {
            text: Language[type].cancel,
            onClick: onClick,
            color: 'bg-danger'
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ShowPopUp.Provider value={{showPopUp, dispatch}}>
                <PopUp>
                    <TitleContent text={Language[type].theOldAccount}/>
                    <MapInputs inputs={popUpEls[0]}/>
                    <TitleContent text={Language[type].mergeWith}/>
                    <MapInputs inputs={popUpEls[1]}/>
                    <div className='row no-gutters justify-content-end'>
                        <MapBtns btns={popUpBtns} hasMarginTop={false} flexFill={false}/>
                    </div>
                </PopUp>
            </ShowPopUp.Provider>
            <ContentSectionHead title={Language[type].accounts}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-lg-4'>
                        <TitleContent text={Language[type].searchOptions}/>
                        <MapInputs inputs={colInputsLeft}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHead}/>
                            </Thead>
                            <Tbody data={rows}/>
                        </Table>
                        <MapLinkRounded links={links}/>
                    </div>
                    <div className='col-lg-8'>
                        <TitleContent text={Language[type].theDetails}/>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <MapInputs inputs={colInputsRight[0]}/>
                            </div>
                            <div className='col-lg-6 align-self-center'>
                                <Table>
                                    <Thead>
                                        <TrThead data={tHeadTo}/>
                                    </Thead>
                                    <Tbody data={rowsTo}/>
                                </Table>
                                <div className='w-100 my-3'>
                                    <MapInputs inputs={colInputsRight[1]}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='justify-content-end d-flex w-100'>
                        <div className='col-lg-6'>
                            <MapBtns btns={buttons} hasMarginTop={false}/>
                        </div>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default Accounts;