import React from 'react';

function BadgeLink({iconClass, count}) {
    return (
        <li className="nav-item px-2 py-0">
            <a className="nav-link text-white p-0">
                <i className={`icofont-${iconClass}`}></i>
                <span className="badge badge-danger position-absolute"><small>{count}</small></span>
            </a>
        </li>
    );
}

export default BadgeLink;