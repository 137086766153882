import React from 'react';
import {Link} from 'react-router-dom';

function VerticalList({lists}) {
    return (
        <ul className="list-group list-group-flush text-left">
            {lists.map((list, index) =>
                <li key={index} className="list-group-item list-group-item-action py-1">
                    <Link
                        className="text-secondary w-100 d-block"
                        to={list.link}>
                        {list.text}
                    </Link>
                </li>
            )}
        </ul>
    );
}

export default VerticalList;