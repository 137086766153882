import React, {useEffect, useReducer} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Login from "../Modules/Login/Login";
import {GetLocalStorage, SetLocalStorage} from "../Helpers/LocalStorage";
import {Localization} from "../Helpers/Localization";
import {DomDirection} from "../Helpers/DomDirection";
import {useHistory} from 'react-router-dom';
import Reducer from "../Helpers/Reducer";
import Localize from "../Helpers/Context";

function Auth({children}) {
    let history = useHistory();
    const auth = true;
    const initLang = 'en';
    const getLocalize = GetLocalStorage('localize');
    const [state, dispatch] = useReducer(Reducer, (!getLocalize) ? initLang : getLocalize.language);

    if (!getLocalize) {
        SetLocalStorage('localize', Localization(initLang, 'ltr'));
    } else {
        DomDirection(getLocalize.direction);
    }
    useEffect(() => {
        let followURL = history.location.pathname.slice(3);
        console.log(followURL);
        history.push(`/${state + followURL}`);
    }, [state]);

    if (auth) {
        return (
            <Localize.Provider value={{state: state, dispatchLang: dispatch}}>
                {children}
            </Localize.Provider>
        );
    } else {
        return (
            <>
                <Redirect to={'/login'}/>
                <Switch>
                    <Route path='/login' component={Login}/>
                </Switch>
            </>
        )
    }
}

export default Auth;
