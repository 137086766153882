import React from 'react';

function ContentSectionTable({children}) {
    return (
        <div className="section-table-container bg-white px-2 py-5 p-md-5">
            {children}
        </div>
    );
}

export default ContentSectionTable;