import * as React from 'react';
import {Route} from 'react-router-dom';
import messagesSendMessage from "../Modules/Messages/Messages-Send-Message";
import messagesAlert from "../Modules/Messages/Messages-Alert";
import {MESSAGES_NESTED_PATH} from "../Helpers/Link-Path";

export function RouteMessage() {
    return (
        <>
            <Route path={MESSAGES_NESTED_PATH.message} component={messagesSendMessage}/>
            <Route path={MESSAGES_NESTED_PATH.alert} component={messagesAlert}/>
        </>
    );
};