import * as React from 'react';
import {TitleCenter} from "../../Components/Titles/Title-Center";
import {CircleList} from "../../Components/Lists/Circle-List";
import MapCircleLinkTextLeft from "../../Helpers/Maps/Map-Circle-Link-Text-Left";
import {REPORTS_NESTED_PATH} from "../../Helpers/Link-Path";
export function Reports() {
    const list = [
        {
            color: 'bg-green',
            imgUrl: 'rp1',
            text: 'Fund Movement',
            path: REPORTS_NESTED_PATH.fundMovement
        },
        {
            color: 'bg-danger',
            imgUrl: 'rp2',
            text: 'Exchange items',
            path: REPORTS_NESTED_PATH.exchangeItems
        },
        {
            color: 'bg-light-blue',
            imgUrl: 'rp3',
            text: 'Daily traffic report',
            path: REPORTS_NESTED_PATH.trafficReport
        },
        {
            color: 'bg-purple',
            imgUrl: 'rp4',
            text: 'Monthly total',
            path: REPORTS_NESTED_PATH.monthlyTotal
        },
        {
            color: 'bg-orange',
            imgUrl: 'rp5',
            text: 'Client reservations',
            path: REPORTS_NESTED_PATH.clientReservations
        },
        {
            color: 'bg-rose',
            imgUrl: 'rp6',
            text: 'Receipt',
            path: REPORTS_NESTED_PATH.receipt
        },
        {
            color: 'bg-warning',
            imgUrl: 'rp7',
            text: 'Bond Services',
            path: REPORTS_NESTED_PATH.bondServices
        },
        {
            color: 'bg-nile',
            imgUrl: 'rp8',
            text: 'Open Reservations',
            path: REPORTS_NESTED_PATH.openReservations
        },
        {
            color: 'bg-secondary',
            imgUrl: 'rp9',
            text: 'messages',
            path: REPORTS_NESTED_PATH.messages
        },
        {
            color: 'bg-red',
            imgUrl: 'rp10',
            text: 'change rooms',
            path: REPORTS_NESTED_PATH.changeRooms
        },
        {
            color: 'bg-success',
            imgUrl: 'rp11',
            text: 'rates',
            path: REPORTS_NESTED_PATH.rates
        },
        {
            color: 'bg-light-blue',
            imgUrl: 'rp12',
            text: 'Yearly in months',
            path: REPORTS_NESTED_PATH.yearlyMonths
        },
        {
            color: 'bg-dark',
            imgUrl: 'rp13',
            text: 'Taxes and Fees',
            path: REPORTS_NESTED_PATH.taxesFees
        },
        {
            color: 'bg-brown',
            imgUrl: 'rp14',
            text: 'Tourism Authority',
            path: REPORTS_NESTED_PATH.tourismAuthority
        },
        {
            color: 'bg-warning',
            imgUrl: 'rp15',
            text: 'bills',
            path: REPORTS_NESTED_PATH.bills
        },
        {
            color: 'bg-danger',
            imgUrl: 'rp16',
            text: 'Staff statistics',
            path: REPORTS_NESTED_PATH.staffStatistics
        },
    ];
    return (
        <>
            <TitleCenter icon={'files-stack'} title={'Reports'}/>
            <CircleList listClass={'text-left'}>
                <MapCircleLinkTextLeft list={list}/>
            </CircleList>
        </>
    );
};