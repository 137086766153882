import * as React from 'react';
import {Route} from 'react-router-dom';
import checkIn from '../Modules/Rooms-Management/Check-In';
import checkOut from '../Modules/Rooms-Management/Check-Out';
import rooms from '../Modules/Rooms-Management/Rooms';
import {ROOM_MANAGEMENT_NESTED_PATH, ROOM_MANAGEMENT_PATH} from "../Helpers/Link-Path";

export function RouteRoomManagement() {
    return (
        <>
            <Route path={ROOM_MANAGEMENT_NESTED_PATH.checkIn} component={checkIn}/>
            <Route path={ROOM_MANAGEMENT_NESTED_PATH.checkOut} component={checkOut}/>
            <Route path={ROOM_MANAGEMENT_NESTED_PATH.rooms} component={rooms}/>
        </>
    );
};