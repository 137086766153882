import * as React from 'react';
export function NotifyDashboard({icon, text, color, notifyNum}) {
    return (
        <li className="nav-item text-capitalize d-flex justify-content-between align-items-center mb-2">
            <span className={color}>
                <i className={`icofont-${icon}`}></i> {text}
            </span>
            <span className="badge border-dark border">{notifyNum}</span>
        </li>
    );
};