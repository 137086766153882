import * as React from 'react';
import {Route} from 'react-router-dom'
import receipt from "../Modules/Bonds-Bills/Receipt";
import services from "../Modules/Bonds-Bills/Services";
import BillOfExchange from "../Modules/Bonds-Bills/Bill_Of_Exchange";
import exchangeBonds from "../Modules/Bonds-Bills/Exchange-Bonds";
import invoices from "../Modules/Bonds-Bills/Invoices";
import {BONDS_BILLS_NESTED_PATH} from "../Helpers/Link-Path";

export function RouteBondsBills() {
    return (
        <>
            <Route path={BONDS_BILLS_NESTED_PATH.receipt} component={receipt}/>
            <Route path={BONDS_BILLS_NESTED_PATH.services} component={services}/>
            <Route path={BONDS_BILLS_NESTED_PATH.exchangeBills} component={BillOfExchange}/>
            <Route path={BONDS_BILLS_NESTED_PATH.exchangeBonds} component={exchangeBonds}/>
            <Route path={BONDS_BILLS_NESTED_PATH.invoices} component={invoices}/>
        </>
    );
};