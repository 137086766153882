import * as React from 'react';

export function TitleLeft({text}) {
    return (
        <div className="col-12 p-0">
            <h4
                className="h4 text-capitalize text-left text-light-blue mb-3 font-weight-bold">
                {text}</h4>
        </div>
    );
};