import React from 'react';

function Thead({children}) {
    return (
        <thead className="text-white">
            {children}
        </thead>
    );
}

export default Thead;