import React from 'react';
import {RoundedBtn} from "../../Components/Buttons/Rounded-Btn";
import {Language} from "../../Lang/Language";

function MapBtns({btns, flexFill, hasMarginTop = true, justify}) {
    return (
        <div className={`row no-gutters ${hasMarginTop && 'mt-3'} ${ justify && `justify-content-${justify}`}`}>
            {btns.map((btn, index) =>
                <RoundedBtn key={index} flexFill={flexFill}
                            text={btn.text}
                            color={btn.color}
                            onClick={btn.onClick}
                            isFormSectionBtn={btn.selection}
                />
            )}
        </div>
    );
}

export default MapBtns;