import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function ExpiresReservations() {
    const {state: type} = useContext(Localize);
    const inputs = [
        {
            element: 'checkbox',
            text: 'Add New Profiles',
        },
        {
            element: 'checkbox',
            text: 'overall rate',
        },
        {
            element: 'checkbox',
            text: 'Over All Rate Code',
        },
        {
            element: 'checkbox',
            text: 'Allow Over Booking ',
        },
        {
            element: 'checkbox',
            text: 'Change Daily Variable Rate Code',
        },
        {
            element: 'checkbox',
            text: 'Bill Direction ',
        },
        {
            element: 'checkbox',
            text: 'Check-In Group Reservation',
        },
        {
            element: 'checkbox',
            text: 'Check-In Reservation ',
        },
        {
            element: 'checkbox',
            text: 'Cancel Group Reservation',
        },
        {
            element: 'checkbox',
            text: 'Cancel In-House Reservation',
        },
    ];
    const buttons = [
        {
            text: Language[type].done,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        }
    ];
    return (
        <div className='row'>
            <div className='col-md-12'>
                <TitleContent text={Language[type].reservationsTerms}/>
                <MapInputs inputs={inputs}/>
                <MapBtns btns={buttons} flexFill={false} justify={'end'}/>
            </div>
        </div>
    );
}

export default ExpiresReservations;