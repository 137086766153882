import * as React from 'react';

export function CircleList({children, divClass = 'mt-5', listClass = 'text-lg-center'}) {
    return (
        <div className={`col-12 ${divClass}`}>
            <ul className={`nav justify-content-lg-center justify-content-md-start text-left ${listClass}`}>
                {children}
            </ul>
        </div>
    );
};