export const en = {
    lang: 'العربية',
    signedIn: 'Signed In',
    signIn: 'Sign In',
    signOut: 'Sign Out',

// SideBar Links
    dashboard: 'Dashboard',
    roomManagement: 'Room Management',
    reservations: 'Reservations',
    frontOffices: 'Front Offices',
    accountants: 'Accountants',
    bondsAndBills: 'Bonds And Bills',
    customers: 'Customers',
    serviceRevenue: 'Services',
    messages: 'Messages',
    reports: 'Reports',
    advantages: 'Advantages',
    statistics: 'statistics',
    nightChecking: 'Night checking',
    help: 'Help',
// buttons
    exit: 'Exit',
    export: 'Export',
    exportAll: 'Export for all',
    timeDateSyncWithDevice: 'Time and date sync with device',
    exportBackup: 'Export Backup',
    search: 'Search',
    addReservation: 'Add Reservation',
    addReservationGroup: 'Add Reservation Group',
    add: 'Add',
    addCustomers: 'Add Customers',
    transferToRoom: 'Transfer To Room',
    transferToServiceRevenue: 'transfer to service revenue',
    delete: 'Delete',
    edit: 'Edit',
    print: 'Print',
    cancel: 'Cancel',
    done: 'Done',
    save: 'save',
    pricePackages: 'Price packages',
    priceDetails: 'Price details',
    rebuildSqlEntities: 'Rebuild Sql Entities',
    correctStatisticsForMonthlyYearlyNightProofingFiles: 'Correct statistics for monthly and yearly night proofing files',
    purgeJornalPostingsOlderThanYears: 'Purge N / A Jornal of Postings Older than 3 Years',
    purgeFoliosOlderThanYears: 'Purge Folios Older than 3 Years',
    fixCreditCardEncryption: 'Fix Credit Card Encryption',
    computers: 'Computers',
    devices: 'Devices',
    deviceGroups: 'Device Groups',
    importMissingAccountsWithDefaultSettings: 'Import Missing Accounts with Default Settings',
    notSubscriber: 'Not Subscriber',
    merge: 'Merge',
// Pagination
    first: 'First',
    last: 'Last',
    next: 'Next',
    previous: 'Previous',
// Inputs
    chooseRoom: 'Choose Room',
    roomName: 'Room Name',
    numberOfRoom: 'Number Of Room',
    floor: 'Floor',
    TheTiming: 'the timing',
    roomType: 'Room Type',
    showCompanies: 'Show Companies',
    dateFrom: 'date From',
    dateTo: 'date To',
    reservationSource: 'Reservation Source',
    reservationSourceNumber: 'Reservation Source Number',
    clientName: 'Client Name',
    phoneNumber: 'Phone Number',
    reservationNumber: 'Reservation Number',
    rentType: 'Rent Type',
    reservationType: 'Reservation Type',
    room: 'Room',
    openReservation: 'Open Reservation',
    contractNo: 'Contract No',
    receiptNo: 'Receipt No',
    nonRelayToMyHotelFinancial: 'Non-Relay To My Hotel Financial',
    bondsServices: 'Bonds Services',
    chargedForReservation: 'Charged For Reservation',
    evidenceName: 'Evidence Name',
    name: 'Name',
    chooseCategory: 'Choose Category',
    totalPrice: 'Total Price',
    discount: 'Discount',
    fine: 'Fine',
    priceAfterSalary: 'Price After Salary',
    finalPrice: 'Final Price',
    valueAdded: 'Value Added',

// 404
    notFound: 'There is no data',
// Table Head
    required: 'Required',
    financial: 'Financial',
    accountName: 'account name',
    referenceNo: 'Reference No',
    reservationStatus: 'Reservation Status',
    client: 'Client',
    duration: 'Duration',
    rent: 'Rent',
    amount: 'Amount',
    tax: 'Tax',
    total: 'Total',
    paid: 'Paid',
    creditor: 'Creditor',
    debit: 'Debit',
    operations: 'Operations',
    receivedFrom: 'Received From',
    for: 'For',
    paymentMethod: 'Payment Method',
    date: 'Date',
    received: 'Received',
    cashier: 'Cashier',
    totalAmount: 'Total Amount',
    amountReceived: 'Amount Received',
    remainingAmount: 'Remaining Amount',
    Tenant: 'Tenant',
    placeOfMerit: 'Place Of Merit',
    dateOfMerit: 'Date Of Merit',
    invoiceNO: 'Invoice NO',
    dateCreated: 'Date Created',
    paidTo: 'Paid To',
    exchangeItem: 'Exchange Item',
    evidenceType: 'Evidence Type',
    evidenceNumber: 'Evidence Number',
    versionRelease: 'Version Release',
    eMailAddress: 'E-Mail Address',
    serviceName: 'Service Name',
    quantity: 'Quantity',
    source: 'source',
    case: 'case',
    choose: 'Choose',

// Dashboard Module
    weeklyApartments: 'The Case Of Weekly Apartments',
    caseApartments: 'The Case Of Apartments',
    empty: 'Empty',
    reserved: 'Reserved',
    clean: 'Clean',
    checkIn: 'Check In',
    checkOut: 'Check Out',
    remainingGuests: 'The Remaining Guests',

// Room Management Module
    advancedSearch: 'Advanced Search',
    results: 'Results',
    rented: 'Rented',
    maintenance: 'Maintenance',
    expiredContracts: 'Expired Contracts',
    reservedNotLogin: 'Reserved Not Login',
    occupancyRate: 'Occupancy Rate',

// Bonds And Bills Module
    Receipt: 'Receipt',
    Services: 'Services',
    billsOfExchange: 'Bills Of Exchange',
    exchangeBonds: 'Exchange Bonds',
    invoices: 'Invoices',
    receiptManagement: 'Receipt Management',
    billsManagement: 'Bills Management',
    servicesManagement: 'Services Management',
    bondsManagement: 'Bonds Management',
    invoicesManagement: 'Invoices Management',

// Customers Module
    customerManagement: 'Customer Management',

// Services Module
    serviceDetails: 'Service Details',
    festivalHalls: 'Festival Halls',

// Messages Module
    sendMessage: 'Send Message',
    sendAlert: 'Send Alert',

// Reports Module
    fundMovement: 'Fund Movement',
    exchangeItems: 'Exchange Items',
    dailyTrafficReport: 'Daily Traffic Report',
    monthlyTotal: 'Monthly Total',
    clientReservations: 'Client Reservations',
    receipt: 'Receipt',
    bondServices: 'Bond Services',
    openReservations: 'Open Reservations',
    changeRooms: 'Change Rooms',
    Rates: 'Rates',
    yearlyInMonths: 'Yearly In Months',
    taxesAndFees: 'Taxes And Fees',
    tourismAuthority: 'Tourism Authority',
    bills: 'Bills',
    staffStatistics: 'Staff Statistics',

// Settings Module
    settings: 'Settings',
    generalSettings: 'General Settings',
    theRooms: 'Rooms',
    theSales: 'the sales',
    thePrices: 'the prices',
    accounting: 'Accounting',
    connectivity: 'Connectivity',
    protection: 'Protection',
    theSystem: 'System',

    hotelDataSettings: 'Hotel data settings',
    contractTermsSettings: 'Contract terms settings',
    businessSourcesAndWebsites: 'Business sources and websites',
    markets: 'Markets',
    regionsAndStates: 'Regions and states',
    reasonsForTheVisit: 'Reasons for the visit',
    documentType: 'Document Type',
    outOfOrder: 'Out of order',
    typesOfReservations: 'Types of reservations',
    arrivalMethod: 'Arrival method',

    hotelCodes: 'Hotel codes',
    theProperties: 'The properties',
    roomTypes: 'Room types',
    furniture: 'Furniture',
    connectingRoomsList: 'Connecting rooms list',
    floorPlanDesign: 'Floor plan design',

    weekend: 'weekend',
    customerRating: 'Customer rating',
    reservationsSettings: 'Reservations settings',

    seasonSeasons: 'Seasons Price',
    priceCategories: 'Price categories',
    packages: 'Packages',
    priceLists: 'Price lists',
    theCurrency: 'the currency',

    accountsGroup: 'Accounts group',
    accountSources: 'Account sources',
    accounts: 'Accounts',
    accountantsSettings: 'Accountants settings',
    paymentMethods: 'payment methods',
    taxAndFeeManagement: 'Tax and Fee Management',
    serviceManagement: 'Service management',

    manageSubscriptions: 'Manage subscriptions',
    connectTheFrontOffices: 'Connect the front offices',
    installTheBackOfficeLinkage: 'Install the back office linkage',
    doorLock: 'Door Lock',
    theRoomsAreInSuns: 'The rooms are in suns',

    sections: 'sections',
    groups: 'Groups',
    userManagement: 'User management',

    theLicense: 'The license',
    theAgreement: 'The agreement',
    termsAndConditions: 'Terms and Conditions',
    databaseMaintenance: 'Database maintenance',
    aboutMyHotel: 'About my hotel',
//Titles

    searchOptions: 'Search options',
    theDetails: 'the details',
    backToPriceLists: 'Back to price lists',
    sortAndSearch: 'Sort and search',
    backToGroups: 'Back to groups',
    userLists: 'User lists',
    lists: 'Lists',
    accountantTerms: 'Accountant Terms',
    reservationsTerms: 'reservations Terms',
    servicesTerms: 'Services Terms',
    backToTheMarket: 'Back to the market',
    countries: 'Countries',
    areas: 'Areas',
    businessSources: 'Business sources',
    locations: 'Locations',
    generalList: 'General List',
    rating: 'Rating',
    roomsName: 'room Names',
    managementMessages: 'Management messages',
    reservationConfirmationMessages: 'Reservation confirmation messages',
    automaticMessagesToEnter: 'Automatic messages to enter',
    automaticMessagesToExit: 'Automatic messages to exit',
    theReservationLetterToTheOwner: 'The reservation letter to the owner',
    sendMessageWhenCreatingReceiptVoucher: 'Send a message when creating a receipt voucher',
    backToAccounts: 'Back to accounts',

// Inputs
    code: 'code',
    theName: 'The name',
    alternativeName: 'alternative name',
    theDefaultAccount: 'The default account',
    price: 'price',
    theEquation: 'The equation',
    calculationMethod: 'calculation method',
    relayTheNextDay: 'Relay the next day',
    addPrice: 'Add price',
    season: 'season',
    startingDate: 'starting date',
    ExpiryDate: 'Expiry date',
    priority: 'priority',
    priceCategory: 'Price category',
    priceSymbol: 'Price symbol',
    address: 'The address',
    showActivePrices: 'Show active prices',
    virtualAccount: 'Virtual account',
    marketSymbol: 'Market symbol',
    sourceCode: 'Source code',
    alternateDescription: 'Alternate description',
    theTextOfTheBill: 'The text of the bill',
    notes: 'notes',
    theRoom: 'the room',
    repetition: 'repetition',
    beginningOfSale: 'Beginning of sale',
    endOfSale: 'End of sale',
    mealPlanner: 'Meal planner',
    breakfast: 'Breakfast',
    lunch: 'lunch',
    dinner: 'dinner',
    ChooseAll: 'Choose all',
    until: 'Until',
    person: 'Person',
    twoPersons: 'Two Person',
    threePerson: 'Three Persons',
    fourPerson: 'Four Persons',
    fivePerson: 'Five Persons',
    internet: 'Internet',
    infant: 'infant',
    baby: 'baby',
    addition: 'addition',
    fullPrice: 'Full price',
    currencySymbol: 'Currency symbol',
    theNameOfTheCurrency: 'currency name',
    theGroup: 'the group',
    theFile: 'The file',
    groupCode: 'Group Code',
    groupName: 'group name',
    alternativeGroupDescription: 'Alternative group description',
    termsOfReference: 'terms of reference',
    userName: 'username',
    fullName: 'Full name',
    password: 'password',
    accountantNumber: 'Accountant number',
    administration: 'Administration',
    dateOfBirth: 'Date of Birth',
    lockedTo: 'Locked to',
    discountLimit: 'Discount limit',
    dateOflastEntry: 'Date of last entry',
    active: 'active',
    all: 'all',
    laundry: 'laundry',
    cafe: 'cafe',
    festivelHalls: 'festivel Halls',
    delayDifference: 'delay Difference',
    fines: 'Fines',
    show: 'Show',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday',
    timeCheckIn: 'time Check In',
    timeCheckOut: 'time Check Out',
    activate: 'activate',
    hour: 'hour',
    pm: 'pm',
    am: 'am',
    gracePeriodForChangingRooms: 'Grace period for changing rooms',
    enteringTheRoomIsObligatoryInTheNewContract: 'Entering the room is obligatory in the new contract',
    activateCheckoutTimeForMonthlyReservations: 'Activate checkout time for monthly reservations',
    whenChangingTheRoomCalculateThePriceOfTheNewApartment: 'When changing the room, calculate the price of the new apartment',
    customerName: 'customer name',
    country: 'country',
    alternativeState: 'Alternative state',
    city: 'city',
    alternativeCity: 'Alternative city',
    engineCode: 'Engine code',
    dACode: 'DA code',
    serverName: 'Server name',
    theNameOfTheDatabase: 'The name of the database',
    sQLServerUser: 'SQL Server User',
    sQLServerPWD: 'SQL Server PWD',
    maximumNumberOfUsers: 'Maximum number of users',
    theMaximumNumberOfRooms: 'The maximum number of rooms',
    version: 'Version',
    expirationDate: 'Expiration date',
    licenseCode: 'License code',
    multiLanguage: 'multi language',
    bOIFC: 'BO IFC',
    HospitalityLogo: 'Hospitality logo',
    experimental: 'experimental',
    hospitality: 'hospitality',
    Suns: 'Suns',
    hotelCode: 'Hotel code',
    theHotelName: 'the hotel name',
    areaCode: 'Area code',
    areaName: 'Area name',
    alternativeLanguageFeatures: 'Alternative language features',
    effectiveShowOnly: 'Effective show only',
    descriptionOne: 'Description 1',
    theFirstAlternativeDescription: 'The first alternative description',
    virtualAccounts: 'Virtual accounts',
    defaultPrice: 'Default price',
    insuranceAmount: 'Insurance amount',
    theNumberOfRooms: 'The number of rooms',
    access: 'Access',
    nightsRoomNumber: 'Nights room number',
    allowManualPosting: 'Allow manual posting',
    transfer: 'Transfer',
    roomService: 'Room service',
    allowTransferFrom: 'Allow transfer from',
    allowTransferTo: 'Allow transfer to',
    telephoneGuide: 'telephone guide',
    theNewspaper: 'The newspaper',
    qiblaDetermination: 'Qibla determination',
    foodMenu: 'food menu',
    refrigerator: 'Refrigerator',
    hall: 'Hall',
    maximumNumberOfRooms: 'Maximum number of rooms',
    certain: 'Certain',
    theYear: 'the year',
    revenueBudget: 'Revenue budget',
    budgetRooms: 'Budget rooms',
    january: 'january',
    february: 'february',
    march: 'march',
    april: 'april',
    may: 'may',
    june: 'june',
    july: 'july',
    august: 'august',
    september: 'september',
    october: 'october',
    nov: 'nov',
    dec: 'dec',
    countryCode: 'country code',
    countryName: 'name of the country',
    StartFrom: 'Start from',
    nationality: 'Nationality',
    sunsCode: 'The symbol of suns',
    theCompanyName: 'The Company name',
    theState: 'The state',
    district: 'City',
    title: 'District',
    companyPhone: 'Title',
    fax: 'Company phone',
    systemTiming: 'Fax',
    addedValueFivePercent: 'System timing',
    currency: 'the currency',
    taxNumber: 'tax Number',
    dateOfRegistration: 'Added value (5%)',
    companyNameInEnglish: 'Company name in English',
    eMail: 'E-mail',
    website: 'website',
    mailBox: 'mail box',
    postalCode: 'Postal code',
    theLogo: 'The logo',
    theMap: 'the map',
    time: 'Time',
    taxesIncluded: 'Taxes included',
    marketCode: 'Market symbol',
    marketClassification: 'Market classification',
    budget: 'budget',
    marketRankings: 'Market rankings',
    descriptionTypeOfIdentity: 'Description type of identity',
    alternativeIdentityDescription: 'Alternative identity description',
    visitCode: 'Visit code',
    descriptionOfTheAccessMethod: 'Description of the access method',
    describeTheAlternativeMethodOfAccess: 'Describe the alternative method of access',
    workSource: 'Work source',
    location: 'the Location',
    details: 'the details',
    thePhone: 'Phone NO',
    TheAccount: 'The account',
    backOfficeAccount: 'Back office account',
    text: 'Text',
    theRatio: 'The ratio',
    costCenterOptional: 'Cost Center / Optional',
    officeAccount: 'Office account',
    yourCurrentBalance: 'Your current balance',
    theNameOfTheSMSSender: 'The name of the SMS sender',
    theNameOfTheADSender: 'The name of the AD sender',
    roomNamesInMessages: 'The name of the rooms in the messages',
    cellPhone: 'cell phone',
    sentTo: 'Sent to',
    todayStartTime: 'Today\'s start time',
    TransmissionTime: 'Transmission time',
    showRoomName: 'Show room name',
    theTextOfTheMessage: 'The text of the message',
    addToTheMessage: 'Add to the message',
    contractNumber: 'contract number',
    dailyRent: 'Daily rent',
    deviceType: 'Device type',
    deviceSet: 'Device set',
    howToCommunicate: 'How to communicate',
    wrench: 'Expiration date',
    systemIcon: 'Wrench',
    systemCode: 'System icon',
    linkType: 'Link type',
    accountNo: 'Account number',
    messageText: 'message Text',
    account: 'account',
    registrationDate: 'Applied to',
    appliedTo: 'registration date',
    subjectToAddedValue: 'Subject to added value',
    feeIncluded: 'Fee included',
    theLimit: 'The limit',
    theNumber: 'the number',
    theBeginningOfCriticism: 'The beginning of criticism',
    timeUsed: 'Time used',
    theBalance: 'The balance',
    theDifference: 'The difference',
    start: 'Start',
    close: 'Close',
    status: 'Status',
    dateOfLastPosting: 'Date of last posting',
    todayTotal: 'Today total',
    totalMonth: 'Total month',
    totalYear: 'Total year',
    calculationFunctions: 'Calculation functions',
    theOldAccount: 'The old account',
    mergeWith: 'Merge with',
    theType: 'Type',
    roomNumber: 'room number',
    features: 'Features',
// Tables
    number: 'number',
    category: 'category',
    description: 'description',
    computerName: 'Computer name',
    roomNumberInSuns: 'Room number in suns',
    arabicName: 'Arabic Name',
    englishName: 'English name',
    subsidiaryServices: 'Subsidiary services',

// Text
    reservationSettingErrOne: 'Very important: Your activation of the entry time will entail an additional day preceding the customer if an entry is made for him before this time',
    reservationSettingErrTwo: 'Very important: your activation of the exit time will result in an additional day count on the client if no liquidation has been made before this time',
    contractTermsSetting: 'Caution: Increasing the terms of the contract by more than 16 lines may cause the rental contract to be printed on two sheets',
    messageSettingsErr: 'One message will be deducted',
    messageSettingsSuccess: 'This service is used to find out the customer evaluation of the apartments performance (The message will be sent the next day at 9 pm)',
};