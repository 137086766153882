import * as React from 'react';
import {Link} from "react-router-dom";
import DefaultList from "../Lists/Default-List";
import BadgeLink from "../Links/badge-Link";
import {useContext} from "react";
import Localize from "../../Helpers/Context";

export function IconList() {
    const localize = useContext(Localize);
    const items = [
        {
            count: 4,
            icon: 'alarm'
        },
        {
            count: 4,
            icon: 'ui-message'
        }
    ];
    return (
        <DefaultList listClass={'menu-setting'}>
            {items.map((item, index) =>
                <BadgeLink key={index} iconClass={item.icon} count={item.count}/>
            )}
            <li className="nav-item px-2 py-0">
                <Link
                    className="nav-link text-white p-0"
                    to={`/${localize.state}/setting`}>
                    <i className="icofont-settings"></i>
                </Link>
            </li>
        </DefaultList>

    );
}
