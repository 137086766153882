import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import {TitleLeft} from "../../Components/Titles/Title-Left";
import MapGridTwoInputs from "../../Helpers/Maps/Map-Grid-Two-Inputs";

function RegionsStates() {
    const {state: type} = useContext(Localize);
    const colInputsLeftOne = [
        {
            element: 'input',
            text: Language[type].areaCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].areaName,
            type: 'text'
        }
    ];
    const colInputsRightOne = [
        {
            element: 'input',
            text: Language[type].areaCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].areaName,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].alternativeName,
            type: 'text'
        }

    ];
    const tHeadOne = [
        Language[type].areaCode,
        Language[type].areaName
    ];
    const rowsOne = [
        ['#', "#"]
    ];
    const colInputsLeftTwo = [
        {
            element: 'input',
            text: Language[type].countryCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].countryName,
            type: 'text'
        }
    ];
    const colInputsRightTwo = [
        [
            {
                element: 'input',
                text: Language[type].countryCode,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].countryName,
                type: 'number'
            },
            {
                element: 'input',
                text: Language[type].alternativeName,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].StartFrom,
                type: 'number'
            }
        ],
        [
            {
                text: Language[type].dateOfRegistration,
                inputs: [
                    {
                        element: 'input',
                        text: Language[type].dateOfRegistration,
                        type: 'date'
                    },
                    {
                        element: 'input',
                        text: Language[type].dateOfRegistration,
                        type: 'date'
                    },
                ]
            },
        ],
        [
            {
                element: 'input',
                text: Language[type].nationality,
                type: 'number'
            },
            {
                element: 'input',
                text: Language[type].sunsCode,
                type: 'number'
            }
        ]
    ];
    const tHeadTwo = [
        Language[type].countryCode,
        Language[type].countryName
    ];
    const rowsTwo = [
        ['#', "#"]
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].delete,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].edit,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].print,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].regionsAndStates}/>
            <ContentSectionTable>
                <TitleLeft text={Language[type].areas}/>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].searchOptions}/>
                        <MapInputs inputs={colInputsLeftOne}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHeadOne}/>
                            </Thead>
                            <Tbody data={rowsOne}/>
                        </Table>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputs inputs={colInputsRightOne}/>
                        <MapBtns btns={buttons} hasMarginTop={false}/>
                    </div>
                </div>
                <hr className='w-100'/>
                <TitleLeft text={Language[type].countries}/>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].searchOptions}/>
                        <MapInputs inputs={colInputsLeftTwo}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHeadTwo}/>
                            </Thead>
                            <Tbody data={rowsTwo}/>
                        </Table>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputs inputs={colInputsRightTwo[0]}/>
                        <MapGridTwoInputs inputs={colInputsRightTwo[1]} col={12}/>
                        <MapInputs inputs={colInputsRightTwo[2]}/>
                        <MapBtns btns={buttons} hasMarginTop={false}/>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default RegionsStates;