import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {CheckBox} from "../../Components/Inputs/CheckBox";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import BackLink from "../../Components/Buttons/Back-Link";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputGrids from "../../Helpers/Maps/Map-Input-Grids";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapGridsTitle from "../../Helpers/Maps/Map-Grids-Title";
import MapGridTwoInputs from "../../Helpers/Maps/Map-Grid-Two-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function MarketsBudget() {
    const {state: type} = useContext(Localize);
    const colInputsLeft = [
        {
            element: 'input',
            text: Language[type].theYear,
            type: 'number'
        }
    ];
    const colInputsRight = [
        [
            {
                element: 'input',
                text: Language[type].theYear,
                type: 'number'
            }
        ],
        [
            {
                text: Language[type].january,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].february,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].march,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].april,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].may,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].june,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].july,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].august,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].september,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].october,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].nov,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].dec,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            }
        ]
    ];
    const girdsTitle = [
        Language[type].revenueBudget,
        Language[type].budgetRooms
    ];
    const tHead = [
        Language[type].theYear,
    ];
    const rows = [
        ['#']
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].delete,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].edit,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].print,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].budget}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-12'>
                        <BackLink text={Language[type].backToTheMarket}/>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].searchOptions}/>
                        <MapInputs inputs={colInputsLeft}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHead}/>
                            </Thead>
                            <Tbody data={rows}/>
                        </Table>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].searchOptions}/>
                        <MapInputs inputs={colInputsRight[0]}/>
                        <div className='row no-gutters align-items-center'>
                            <div className='col-lg-6'>
                                <MapGridsTitle titles={girdsTitle}/>
                            </div>
                            <div className='col-lg-6'>
                                <MapGridsTitle titles={girdsTitle}/>
                            </div>
                        </div>
                        <MapGridTwoInputs inputs={colInputsRight[1]}/>
                        <MapBtns btns={buttons}/>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default MarketsBudget;