import React, {useContext} from 'react';
import {ShowPopUp} from "../../Helpers/Context";
import TableIconList from "../Lists/Table-Icon-List";

function PopUp({children}) {
    const {showPopUp, dispatch} = useContext(ShowPopUp);
    const body = document.body.classList;
    if (showPopUp) body.add('modal-open')
    else body.remove('modal-open')
    const onclick = () => {
        dispatch(false)
    }
    return (
        <>
            <div className={`modal-backdrop fade d-none ${showPopUp && 'show d-block'}`}></div>
            <div className={`modal fade ${showPopUp && 'show d-block'}`}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content popup">
                        <div className="mt-3 w-100 mx-1">
                            <TableIconList buttons={[{func: onclick, icon: 'close-circled'}]}/>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PopUp;