import * as React from 'react';
import { Logo } from './Logo';
import { IconList } from './Icon-List';
import { LangList } from './Lang-List';
import { SocialList } from './Social-List';

export function Header() {
    return (
        <>
            <header>
                <nav className="menu p-3 d-flex align-items-center position-fixed">
                    <Logo/>
                    <IconList/>
                    <LangList/>
                    <SocialList/>
                </nav>
            </header>
        </>
    );
};