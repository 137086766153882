import * as React from 'react';
import {Link} from "react-router-dom";

export function CircleTextLeft({imgUrl, componentPath, text, circleColor}) {
    return (
        <li className="nav-item col-md-6 col-lg-4">
            <Link className="nav-link text-capitalize text-dark d-inline-block py-0 my-2"
                  to={componentPath}>
                <div className="d-flex p-0 align-items-center">
                    <div className={`section-circle col-auto p-4 rounded-pill ${circleColor}`}>
                        <img
                            className="h-100 w-100"
                            src={`assets/img/${imgUrl}.png`}
                        />
                    </div>
                    <span className="h5 mx-1 my-0 py-0">{text}</span>
                </div>
            </Link>
        </li>
    );
};