import React from 'react';
import {CheckBox} from "../../Components/Inputs/CheckBox";

function ExpireCheckboxes({inputs, hasMarginBottom = false}) {
    return (
        <>
            {inputs.map(checkbox =>
                <div className={`text-left row no-gutters w-100 ${hasMarginBottom && 'mb-3'}`}>
                    <div className='col-md-3'>
                        <CheckBox labelText={checkbox.parent.text}/>
                    </div>
                    <div className='col-md d-flex'>
                        {checkbox.children.map(item =>
                            <CheckBox labelText={item.text}/>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default ExpireCheckboxes;