import * as React from 'react';
import { RouteSidebar } from "./Route-Sidebar";
import { RouteBondsBills } from './Route-Bonds-Bills';
import { RouteHeader } from './Route-Header';
import { RouteMessage } from './Route-Message';
import { RouteReport } from './Route-Report';
import { RouteRoomManagement } from './Route-Room-Management';
import { RouteSetting } from './Route-setting';

export function Routes() {
    return (
        <>
            <RouteHeader />
            <RouteSidebar />
            <RouteBondsBills />
            <RouteMessage />
            <RouteReport />
            <RouteRoomManagement />
            <RouteSetting />
            {/*<Route path={'*'} component={DataNotFound}/>*/}
        </>
    );
};