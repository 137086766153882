import * as React from 'react';
import Select from "../Components/Inputs/Select";

export function ContentForm({children, marginTop = 'mt-3'}) {
    return (
        <div className={`${marginTop} col-12`}>
            <form className="row justify-content-lg-center no-gutters">
                {children}
            </form>
        </div>
    );
};