import * as React from 'react';
import {Switch} from "react-router-dom";
import {Routes} from "../Routes/Routes";

export function Content() {
    return (
        <section className="content px-3 ml-xl-auto">
            <section className="section w-100 mb-5">
                <div className="mt-5 row">
                    <Switch>
                        <Routes/>
                    </Switch>
                </div>
            </section>
        </section>
    );
}