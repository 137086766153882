import React from 'react';

function SectionHeadBtn({btnIcon, btnText, btnColor = 'bg-light-black', btnClass, hasLeftAuto = true}) {
    return (
        <button
            className={`btn bg-light-black text-white rounded-pill text-capitalize col-md-12 col-lg-auto m-0 p-2 my-2 ${hasLeftAuto && 'ml-lg-auto'} ${btnColor} ${btnClass}`}>
            <i className={`icofont-${btnIcon} bg-white rounded-pill p-1 text-dark`}></i>
            <span className="text-capitalize mx-2">{btnText}</span>
        </button>
    );
}

export default SectionHeadBtn;