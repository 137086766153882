import * as React from 'react';
import {NavLink} from "react-router-dom";
import {useContext} from "react";
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import SidebarList from "../Lists/Sidebar-List";
import {
    DASHBOARD_PATH, MESSAGES_PATH, REPORTS_PATH,
    RESERVATION_PATH,
    ROOM_MANAGEMENT_PATH,
    SERVICES_PATH,
    SETTING_PATH
} from "../../Helpers/Link-Path";

export function SidebarLists() {
    const {state: type} = useContext(Localize);
    const lists = [
        {
            link: DASHBOARD_PATH + type,
            text: Language[type].dashboard,
            icon: 'dashboard-web'
        },
        {
            link: `/${type + SETTING_PATH}`,
            text: Language[type].settings,
            icon: 'tools-alt-2'
        },
        {
            link: `/${type + RESERVATION_PATH}`,
            text: Language[type].reservations,
            icon: 'prescription'
        },
        {
            link: `/${type + ROOM_MANAGEMENT_PATH}`,
            text: Language[type].frontOffices,
            icon: 'building-alt'
        },
        {
            link: `/${type + ''}`,
            text: Language[type].accountants,
            icon: 'ui-calculator'
        },
        {
            link: `/${type + SERVICES_PATH}`,
            text: Language[type].serviceRevenue,
            icon: 'ui-browser'
        },
        {
            link: `/${type + MESSAGES_PATH}`,
            text: Language[type].messages,
            icon: 'inbox'
        },
        {
            link: `/${type + REPORTS_PATH}`,
            text: Language[type].reports,
            icon: 'files-stack'
        },
        {
            link: `/${type + ''}`,
            text: Language[type].advantages,
            icon: 'favourite'
        },
        {
            link: `/${type + ''}`,
            text: Language[type].nightChecking,
            icon: 'search-document'
        },
        {
            link: `/${type + ''}`,
            text: Language[type].help,
            icon: 'question-circle'
        }

    ];
    return (
        <nav className="sidebar-nav">
            <SidebarList lists={lists}/>
            {/*<li className="nav-item">*/}
            {/*<NavLink className="nav-link text-white px-4 text-capitalize text-left"*/}
            {/*to={`/${type}/room-management`}><i*/}
            {/*className="icofont-building-alt"></i><span*/}
            {/*className="px-2">Room management</span>*/}
            {/*</NavLink>*/}
            {/*</li>*/}

            {/*<li className="nav-item">*/}
            {/*<NavLink className="nav-link text-white px-4 text-capitalize text-left"*/}
            {/*to={`/${type}/bonds-and-bills`}><i className="icofont-bill"></i><span*/}
            {/*className="px-2">Bonds and bills</span>*/}
            {/*</NavLink>*/}
            {/*</li>*/}
            {/*<li className="nav-item">*/}
            {/*<NavLink className="nav-link text-white px-4 text-capitalize text-left"*/}
            {/*to={`/${type}/customers`}><i className="icofont-user-suited"></i><span*/}
            {/*className="px-2">Customers</span></NavLink>*/}
            {/*</li>*/}
        </nav>
    );
};