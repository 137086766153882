import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function License() {
    const {state: type} = useContext(Localize);
    const colInputsLeft = [
        {
            element: 'input',
            text: Language[type].clientName,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].alternativeName,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].country,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].alternativeState,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].city,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].alternativeCity,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].engineCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].dACode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].serverName,
            type: 'text'
        },
    ];
    const colInputsRight = [

        {
            element: 'input',
            text: Language[type].theNameOfTheDatabase,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].sQLServerUser,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].sQLServerPWD,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].maximumNumberOfUsers,
            type: 'number'
        },
        {
            element: 'input',
            text: Language[type].theMaximumNumberOfRooms,
            type: 'number'
        },
        {
            element: 'select',
            text: Language[type].version,
            options: [
                {
                    val: '',
                    text: 'choose'
                }
            ]
        },
        {
            element: 'select',
            text: Language[type].expirationDate,
            options: [
                {
                    val: '',
                    text: 'choose'
                }
            ]
        },
        {
            element: 'input',
            text: Language[type].licenseCode,
            type: 'number'
        }
    ];
    const buttons = [
        {
            text: Language[type].done,
            color: 'bg-success',
            onClick: '',
        },

        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].theLicense}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-6'>
                        <MapInputs inputs={colInputsLeft}/>
                    </div>
                    <div className='col-md-6'>
                        <MapInputs inputs={colInputsRight}/>
                        <div className='d-flex justify-content-end w-100'>
                            <MapBtns btns={buttons} flexFill={false} hasMarginTop={false}/>
                        </div>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default License;