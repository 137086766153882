import * as React from 'react';
import { ProfileCard } from './Profile-Card';
import { SidebarLists } from './Sidebar-List';

export function Sidebar() {
    return (
        <aside className="sidebar pb-3 col-auto px-0 d-none d-xl-block position-fixed">
            <ProfileCard/>
            <SidebarLists/>
        </aside>
    );
};