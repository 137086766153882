import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {SETTING_NESTED_PATH} from "../../Helpers/Link-Path";
import MapMultipleCheckbox from "../../Helpers/Maps/Map-Multiple-Checkbox";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapLinkRounded from "../../Helpers/Maps/Map-Link-Rounded";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import BackLink from "../../Components/Buttons/Back-Link";
import MapInputGrids from "../../Helpers/Maps/Map-Input-Grids";
import MapGridTwoInputs from "../../Helpers/Maps/Map-Grid-Two-Inputs";
import MapGridsTitle from "../../Helpers/Maps/Map-Grids-Title";
import {CheckBox} from "../../Components/Inputs/CheckBox";

function PriceDetails() {
    const {state: type} = useContext(Localize);
    const colInputsLeftOne = [
        {
            element: 'input',
            text: Language[type].priceSymbol,
            type: 'text'
        }
    ];
    const colInputsLeftTwo = [
        {
            element: 'input',
            text: Language[type].startingDate,
            type: 'date'
        },
        {
            element: 'input',
            text: Language[type].until,
            type: 'date'
        },
        {
            element: 'input',
            text: Language[type].ExpiryDate,
            type: 'date'
        },
        {
            element: 'input',
            text: Language[type].until,
            type: 'date'
        }
    ];
    const colInputsRight = [
        [
            {
                element: 'input',
                text: Language[type].season,
                type: 'number'
            },
            {
                element: 'checkbox',
                text: Language[type].internet,
            },
            {
                element: 'input',
                text: Language[type].startingDate,
                type: 'date'
            },
            {
                element: 'input',
                text: Language[type].ExpiryDate,
                type: 'date'
            },
        ],
        [
            {
                text: Language[type].person,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].twoPersons,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].threePerson,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].fourPerson,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].fivePerson,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].infant,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].baby,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            },
            {
                text: Language[type].addition,
                inputs: [
                    {
                        element: 'input',
                        type: 'number'
                    },
                    {
                        element: 'input',
                        type: 'number'
                    },
                ]
            }
        ]
    ];
    const girdsTitle = [
        Language[type].price,
        Language[type].fullPrice
    ];
    const tHead = [
        Language[type].startingDate,
        Language[type].ExpiryDate
    ];
    const rows = [
        ['#', '#']
    ];
    const tHeadTwo = [
        '',
        Language[type].code,
        Language[type].description,
    ];
    const rowsTwo = [
        [<CheckBox/>, '#', '#']
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].delete,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].edit,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].print,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].priceDetails}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-6'>
                        <BackLink text={Language[type].backToPriceLists}/>
                        <MapInputs inputs={colInputsLeftOne}/>
                        <TitleContent text={Language[type].sortAndSearch}/>
                        <MapInputGrids inputs={colInputsLeftTwo}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHead}/>
                            </Thead>
                            <Tbody data={rows}/>
                        </Table>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputGrids inputs={colInputsRight[0]}/>
                        <div className='row no-gutters align-items-center'>
                            <div className='col-lg-6'>
                                <MapGridsTitle titles={girdsTitle}/>
                            </div>
                            <div className='col-lg-6'>
                                <MapGridsTitle titles={girdsTitle}/>
                            </div>
                        </div>
                        <MapGridTwoInputs inputs={colInputsRight[1]}/>
                        <TitleContent text={Language[type].roomTypes}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHeadTwo}/>
                            </Thead>
                            <Tbody data={rowsTwo}/>
                        </Table>
                        <MapBtns btns={buttons}/>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default PriceDetails;