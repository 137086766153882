import React from 'react';

function TrThead({data}) {
    const {length} = data;
    return (
        <tr className="text-capitalize text-center">
            {data.map((item, index) =>
                (typeof item === 'object') ?
                    <th key={index} className={`border-white py-3 bg-normal-black ${length === 1 && 'top-rounded'}`}
                        colSpan={item.col}>
                        {item.text}
                    </th>
                    :
                    <th key={index} className={`border-white py-3 bg-normal-black ${length === 1 && 'top-rounded'}`}>
                        {item}
                    </th>
            )}
        </tr>
    );
}

export default TrThead;