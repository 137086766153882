import React from 'react';
import {Input} from "../../Components/Inputs/Input";
import Select from "../../Components/Inputs/Select";


function MapFormFlexFill({formInputs}) {
    return (
        formInputs.map((elm, index) =>
            (elm.tagType === 'input') ?
                <Input key={index} type={elm.inputType} placeHolder={elm.placeHolder}/>
                :
                <Select key={index}>
                    {
                        elm.options.map((option, index) =>
                            <option key={index} value={option.val}>{option.text}</option>
                        )
                    }
                </Select>
        )
    );
}

export default MapFormFlexFill;