import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function Agreement() {
    const {state: type} = useContext(Localize);
    const col = [
        {
            element: 'textarea',
            text: Language[type].theAgreement,
            row: 10
        }
    ];
    const buttons = [
        {
            text: Language[type].print,
            color: 'bg-primary',
            onClick: '',
        },

        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].theAgreement}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-12'>
                        <MapInputs inputs={col}/>
                        <div className='d-flex justify-content-end'>
                            <MapBtns btns={buttons} flexFill={false} hasMarginTop={false}/>
                        </div>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default Agreement;