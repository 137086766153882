import * as React from 'react';
import {useHistory} from 'react-router-dom';

export function RoundedBtn({color = "btn-primary", text, onClick, disabled = false, isFormSectionBtn = false, flexFill= true}) {
    let history = useHistory();
    if (!isFormSectionBtn) {
        return (
            <div className={`form-group mb-3 col-lg-auto col-md-12 px-1 ${flexFill && 'flex-fill'}`}>
                <button
                    className={`btn text-white rounded-pill text-capitalize col-lg-auto w-100 ${color}`}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {text}
                </button>
            </div>
        );
    } else {

        color = 'btn-danger';
        return (
            <div className={`form-group mb-3 col-lg-auto col-md-12 px-1 ${flexFill && 'flex-fill'}`}>
                <button
                    className={`btn text-white rounded-pill text-capitalize col-lg-auto w-100 ${color}`}
                    onClick={() => history.goBack()}
                >
                    {text}
                </button>
            </div>
        );
    }
};