import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import {TitleLeft} from "../../Components/Titles/Title-Left";

function BusinessSourcesWebsites() {
    const {state: type} = useContext(Localize);
    const colInputsLeftOne = [
        {
            element: 'input',
            text: Language[type].workSource,
            type: 'text'
        }
    ];
    const colInputsRightOne = [
        {
            element: 'input',
            text: Language[type].workSource,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].description,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].alternateDescription,
            type: 'text'
        },

    ];
    const tHeadOne = [
        Language[type].workSource,
        Language[type].description
    ];
    const rowsOne = [
        ['#', "#"]
    ];
    const colInputsLeftTwo = [
        {
            element: 'input',
            text: Language[type].location,
            type: 'text'
        }
    ];
    const colInputsRightTwo = [
        {
            element: 'input',
            text: Language[type].location,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].thePhone,
            type: 'number'
        },
        {
            element: 'input',
            text: Language[type].alternativeName,
            type: 'text'
        },

    ];
    const tHeadTwo = [
        Language[type].location,
        Language[type].thePhone
    ];
    const rowsTwo = [
        ['#', "#"]
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].delete,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].edit,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].print,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].businessSourcesAndWebsites}/>
            <ContentSectionTable>
                <TitleLeft text={Language[type].businessSources}/>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].sortAndSearch}/>
                        <MapInputs inputs={colInputsLeftOne}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHeadOne}/>
                            </Thead>
                            <Tbody data={rowsOne}/>
                        </Table>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputs inputs={colInputsRightOne}/>
                        <MapBtns btns={buttons}/>
                    </div>
                </div>
                <hr className='w-100'/>
                <TitleLeft text={Language[type].location}/>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].sortAndSearch}/>
                        <MapInputs inputs={colInputsLeftTwo}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHeadTwo}/>
                            </Thead>
                            <Tbody data={rowsTwo}/>
                        </Table>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputs inputs={colInputsRightTwo}/>
                        <MapBtns btns={buttons}/>
                    </div>
                </div>
            </ContentSectionTable>

        </ContentSection>
    );
}

export default BusinessSourcesWebsites;