import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import CardInputs from "../../Components/Cards/Card-Inputs";

function ManageSubscriptions() {
    const {state: type} = useContext(Localize);
    const inputs = [
        [
            {
                element: 'checkbox',
                text: Language[type].activate,
            },
            {
                element: 'input',
                text: Language[type].userName,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].password,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].ExpiryDate,
                type: 'text'
            }
        ],
        [
            {
                element: 'checkbox',
                text: Language[type].activate,
            },
            {
                element: 'input',
                text: Language[type].userName,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].password,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].ExpiryDate,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].systemIcon,
                type: 'text'
            }
        ],
    ];
    const button = [
        [
            {
                text: Language[type].save,
                onClick: '',
            }
        ],
        [
            {
                text: Language[type].save,
                onClick: '',
            }
        ]
    ];
    const contentBtns = [
        {
            text: Language[type].save,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true
        }
    ];
    const logos = [
        'su',
        'tu'
    ]
    const subscribeBtns = [
        [
            {
                text: Language[type].notSubscriber,
                color: 'bg-dark',
                onClick: '',
            }
        ],
        [
            {
                text: Language[type].notSubscriber,
                color: 'bg-dark',
                onClick: '',
            }
        ]
    ]
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].manageSubscriptions}/>
            <ContentSectionTable>
                <div className='row align-items-stretch'>
                    {
                        inputs.map((input, index) =>
                            <CardInputs key={index}>
                                <div className='row no-gutters justify-content-between align-items-center'>
                                    <img className='mb-3' src={`../../assets/img/${logos[index]}.png`}/>
                                    <MapBtns btns={subscribeBtns[index]} flexFill={false} hasMarginTop={false}/>
                                </div>
                                <MapInputs inputs={input}/>
                                <MapBtns btns={button[index]} flexFill={false} hasMarginTop={false} justify={'center'}/>
                            </CardInputs>
                        )
                    }
                </div>
                <MapBtns btns={contentBtns} flexFill={false} justify={'end'} hasMarginTop={false}/>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default ManageSubscriptions;