import React, {useContext, useReducer} from 'react';
import DefaultList from "../Lists/Default-List";
import {DefaultLink} from "../Links/Default-Link";
import {GetLocalStorage, SetLocalStorage} from "../../Helpers/LocalStorage";
import {Localization} from "../../Helpers/Localization";
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";

export function LangList() {
    let localize = useContext(Localize);
    const onclick = () => {
        const {language} = GetLocalStorage('localize');
        if (language === 'en') {
            SetLocalStorage('localize', Localization('ar', 'rtl'));
            localize.dispatchLang('ar');
        } else {
            SetLocalStorage('localize', Localization('en', 'ltr'));
            localize.dispatchLang('en');
        }
    };
    return (
        <DefaultList listClass={'menu-lang'}>
            <DefaultLink onClick={onclick} iconClass={'earth'} text={Language[localize.state].lang}/>
        </DefaultList>
    );
}