import * as React from 'react';

export function CheckBox({labelText}) {
    return (
        <div className="px-2">
            <div className="form-group form-check m-0">
                <input className="bg-white form-check-input" type="checkbox"/>
                <label className="text-capitalize form-check-label text-secondary">{labelText}</label>
            </div>
        </div>
    );
};