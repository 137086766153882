import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function ExpiresAccountants() {
    const {state: type} = useContext(Localize);
    const inputs = [
        {
            element: 'checkbox',
            text: 'Modify Supplement & Referance Only',
        },
        {
            element: 'checkbox',
            text: 'Bill Information',
        },
        {
            element: 'checkbox',
            text: 'Correct Posting',
        },
        {
            element: 'checkbox',
            text: 'Delete Posting',
        },
        {
            element: 'checkbox',
            text: 'Exchange Rate',
        },
        {
            element: 'checkbox',
            text: 'Export Folio',
        },
        {
            element: 'checkbox',
            text: 'Folio History',
        },
        {
            element: 'checkbox',
            text: 'Include Exclude Folio History',
        },
        {
            element: 'checkbox',
            text: 'User Other Cashier Number',
        },
        {
            element: 'checkbox',
            text: 'Modify Profile Points',
        },
    ];
    const buttons = [
        {
            text: Language[type].done,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        }
    ];
    return (
        <div className='row'>
            <div className='col-md-12'>
                <TitleContent text={Language[type].accountantTerms}/>
                <MapInputs inputs={inputs}/>
                <MapBtns btns={buttons} flexFill={false} justify={'end'}/>
            </div>
        </div>
    );
}

export default ExpiresAccountants;