import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";

export function ProfileCard() {
    const {state: type} = useContext(Localize);
    return (
        <div className="sidebar-admin w-100 my-4">
            <figure className="d-flex p-3 m-0 text-left">
                <img className="mr-2 rounded-pill"
                     src="https://via.placeholder.com/50"/>
                <figcaption className="text-white px-2">
                    <h3 className="h5 w-100">Mohamed Awaad</h3>
                    <h4 className="h6 w-100 font-italic">Awaad</h4>
                    <h5 className="h5 w-100">{Language[type].signedIn}</h5>
                </figcaption>
            </figure>
            <hr className="border-light m-0"/>
        </div>
    );
};