import React from 'react';
import {NavLink} from "react-router-dom";
function SidebarList({lists}) {
    return (
        <ul className="nav flex-column">
            {lists.map((list, index) =>
                <li key={index} className="nav-item">
                    <NavLink
                        className="d-flex align-items-center nav-link text-white px-4 w-100 text-capitalize text-left"
                        exact to={list.link}>
                        <i
                            className={`icofont-${list.icon}`}></i><span
                        className="px-2">{list.text}</span>
                    </NavLink>
                </li>
            )}
        </ul>
    );
}

export default SidebarList;