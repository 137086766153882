import * as React from 'react';
import {Route} from 'react-router-dom';

import {SETTING_NESTED_PATH} from "../Helpers/Link-Path";
import {useContext} from "react";
import Localize from "../Helpers/Context";
import HotelData from "../Modules/Settings/Hotel-Data";
import ContractTerms from "../Modules/Settings/Contract-Terms";
import BusinessSourcesWebsites from "../Modules/Settings/Business-Sources-Websites";
import Markets from "../Modules/Settings/Markets";
import RegionsStates from "../Modules/Settings/Regions-States";
import ReasonsVisit from "../Modules/Settings/Reasons-Visit";
import DocumentType from "../Modules/Settings/Document-Type";
import OutOfOrder from "../Modules/Settings/Out-Of-Order";
import ReservationsTypes from "../Modules/Settings/Reservations-Types";
import ArrivalMethod from "../Modules/Settings/Arrival-Method";
import HotelCodes from "../Modules/Settings/Hotel-Codes";
import Properties from "../Modules/Settings/Properties";
import RoomTypes from "../Modules/Settings/Room-Types";
import Rooms from "../Modules/Settings/Rooms";
import Furniture from "../Modules/Settings/Furniture";
import ConnectingRoomsList from "../Modules/Settings/Connecting-Rooms-List";
import FloorPlanDesign from "../Modules/Settings/Floor-Plan-Design";
import Weekend from "../Modules/Settings/Weekend";
import CustomerRating from "../Modules/Settings/Customer-Rating";
import Reservations from "../Modules/Settings/Reservations";
import SeasonPrices from "../Modules/Settings/Season-Prices";
import Packages from "../Modules/Settings/Packages";
import PriceCategories from "../Modules/Settings/Price-Categories";
import PriceLists from "../Modules/Settings/Price-Lists";
import Currency from "../Modules/Settings/Currency";
import AccountsGroup from "../Modules/Settings/Accounts-Group";
import AccountSources from "../Modules/Settings/Account-Sources";
import Accounts from "../Modules/Settings/Accounts";
import Accountants from "../Modules/Settings/Accountants";
import PaymentMethods from "../Modules/Settings/Payment-Methods";
import TaxFeeManagement from "../Modules/Settings/Tax-Fee-Management";
import ServiceManagement from "../Modules/Settings/Service-Management";
import ManageSubscriptions from "../Modules/Settings/Manage-Subscriptions";
import ConnectFrontOffices from "../Modules/Settings/Connect-Front-Offices";
import InstallBackOfficeLinkage from "../Modules/Settings/Install-Back-Office-Linkage";
import DoorLock from "../Modules/Settings/Door-Lock";
import Messages from "../Modules/Settings/Messages";
import RoomsInSuns from "../Modules/Settings/Rooms-In-Suns";
import Sections from "../Modules/Settings/Sections";
import Groups from "../Modules/Settings/Groups";
import UserManagement from "../Modules/Settings/User-Management";
import License from "../Modules/Settings/License";
import Agreement from "../Modules/Settings/Agreement";
import TermsAndConditions from "../Modules/Settings/Terms-And-Conditions";
import DatabaseMaintenance from "../Modules/Settings/Database-Maintenance";
import PricePackages from "../Modules/Settings/Price-Packages";
import PriceDetails from "../Modules/Settings/Price-Details";
import MarketsBudget from "../Modules/Settings/Markets-Budget";
import AccountsBudget from "../Modules/Settings/Accounts-Budget";
import Expires from "../Modules/Settings/Expires";

export function RouteSetting() {
    const localize = '/' + useContext(Localize).state;
    return (
        <>
            <Route path={localize + SETTING_NESTED_PATH.hotelData} component={HotelData}/>
            <Route path={localize + SETTING_NESTED_PATH.termsContract} component={ContractTerms}/>
            <Route path={localize + SETTING_NESTED_PATH.businessSourcesWebsites} component={BusinessSourcesWebsites}/>
            <Route exact path={localize + SETTING_NESTED_PATH.markets} component={Markets}/>
            <Route path={localize + SETTING_NESTED_PATH.budget} component={MarketsBudget}/>
            <Route path={localize + SETTING_NESTED_PATH.regionsAndStates} component={RegionsStates}/>
            <Route path={localize + SETTING_NESTED_PATH.reasonsForTheVisit} component={ReasonsVisit}/>
            <Route path={localize + SETTING_NESTED_PATH.documentType} component={DocumentType}/>
            <Route path={localize + SETTING_NESTED_PATH.outOfOrder} component={OutOfOrder}/>
            <Route path={localize + SETTING_NESTED_PATH.typesOfReservations} component={ReservationsTypes}/>
            <Route path={localize + SETTING_NESTED_PATH.arrivalMethod} component={ArrivalMethod}/>
            <Route path={localize + SETTING_NESTED_PATH.hotelCodes} component={HotelCodes}/>
            <Route path={localize + SETTING_NESTED_PATH.properties} component={Properties}/>
            <Route path={localize + SETTING_NESTED_PATH.roomTypes} component={RoomTypes}/>
            <Route path={localize + SETTING_NESTED_PATH.rooms} component={Rooms}/>
            <Route path={localize + SETTING_NESTED_PATH.furniture} component={Furniture}/>
            <Route path={localize + SETTING_NESTED_PATH.connectingRoomsList} component={ConnectingRoomsList}/>
            <Route path={localize + SETTING_NESTED_PATH.floorPlanDesign} component={FloorPlanDesign}/>
            <Route path={localize + SETTING_NESTED_PATH.weekend} component={Weekend}/>
            <Route path={localize + SETTING_NESTED_PATH.customerRating} component={CustomerRating}/>
            <Route path={localize + SETTING_NESTED_PATH.reservations} component={Reservations}/>
            <Route path={localize + SETTING_NESTED_PATH.seasonPrices} component={SeasonPrices}/>
            <Route path={localize + SETTING_NESTED_PATH.packages} component={Packages}/>
            <Route path={localize + SETTING_NESTED_PATH.priceCategories} component={PriceCategories}/>
            <Route exact path={localize + SETTING_NESTED_PATH.priceLists} component={PriceLists}/>
            <Route path={localize + SETTING_NESTED_PATH.priceListsPackages} component={PricePackages}/>
            <Route path={localize + SETTING_NESTED_PATH.priceListsDetails} component={PriceDetails}/>
            <Route path={localize + SETTING_NESTED_PATH.currency} component={Currency}/>
            <Route path={localize + SETTING_NESTED_PATH.accountsGroup} component={AccountsGroup}/>
            <Route path={localize + SETTING_NESTED_PATH.accountSources} component={AccountSources}/>
            <Route exact path={localize + SETTING_NESTED_PATH.accounts} component={Accounts}/>
            <Route path={localize + SETTING_NESTED_PATH.accountsBudget} component={AccountsBudget}/>
            <Route path={localize + SETTING_NESTED_PATH.accountants} component={Accountants}/>
            <Route path={localize + SETTING_NESTED_PATH.paymentMethods} component={PaymentMethods}/>
            <Route path={localize + SETTING_NESTED_PATH.taxFeeManagement} component={TaxFeeManagement}/>
            <Route path={localize + SETTING_NESTED_PATH.serviceManagement} component={ServiceManagement}/>
            <Route path={localize + SETTING_NESTED_PATH.manageSubscriptions} component={ManageSubscriptions}/>
            <Route path={localize + SETTING_NESTED_PATH.connectFrontOffices} component={ConnectFrontOffices}/>
            <Route path={localize + SETTING_NESTED_PATH.installBackOfficeLinkage} component={InstallBackOfficeLinkage}/>
            <Route path={localize + SETTING_NESTED_PATH.doorLock} component={DoorLock}/>
            <Route path={localize + SETTING_NESTED_PATH.messages} component={Messages}/>
            <Route path={localize + SETTING_NESTED_PATH.roomsInSuns} component={RoomsInSuns}/>
            <Route path={localize + SETTING_NESTED_PATH.sections} component={Sections}/>
            <Route exact path={localize + SETTING_NESTED_PATH.groups} component={Groups}/>
            <Route path={localize + SETTING_NESTED_PATH.groupsExpires} component={Expires}/>
            <Route path={localize + SETTING_NESTED_PATH.userManagement} component={UserManagement}/>
            <Route path={localize + SETTING_NESTED_PATH.license} component={License}/>
            <Route path={localize + SETTING_NESTED_PATH.agreement} component={Agreement}/>
            <Route path={localize + SETTING_NESTED_PATH.termsConditions} component={TermsAndConditions}/>
            <Route path={localize + SETTING_NESTED_PATH.databaseMaintenance} component={DatabaseMaintenance}/>
        </>
    );
};