import * as React from 'react';
import {TitleCenter} from "../../Components/Titles/Title-Center";
import {BONDS_BILLS_NESTED_PATH} from "../../Helpers/Link-Path";
import {CircleList} from "../../Components/Lists/Circle-List";
import MapCircleLinkTextLeft from "../../Helpers/Maps/Map-Circle-Link-Text-Left";

export function BondsBills() {
    const list = [
        {
            color: 'bg-green',
            text: 'Receipt',
            imgUrl: 'receipt',
            path: BONDS_BILLS_NESTED_PATH.receipt
        },
        {
            color: 'bg-danger',
            text: 'Services',
            imgUrl: 'serv',
            path: BONDS_BILLS_NESTED_PATH.services
        },
        {
            color: 'bg-light-blue',
            text: 'bills-of-exchange',
            imgUrl: 'exchange',
            path: BONDS_BILLS_NESTED_PATH.exchangeBills
        },
        {
            color: 'bg-rose',
            text: 'Exchange bonds',
            imgUrl: 'Bonds',
            path: BONDS_BILLS_NESTED_PATH.exchangeBonds
        },
        {
            color: 'bg-orange',
            text: 'Invoices',
            imgUrl: 'invoice',
            path: BONDS_BILLS_NESTED_PATH.invoices
        }
    ];
    return (
        <>
            <TitleCenter title={'bonds and bills'} icon={'bill'}/>
            <CircleList>
                <MapCircleLinkTextLeft list={list}/>
            </CircleList>
        </>
    );
};