import React from 'react';
import {Input} from "../../Components/Inputs/Input";
import Select from "../../Components/Inputs/Select";

function MapGridTwoInputs({inputs, col = 6}) {
    return (
        <div className='row align-items-center no-gutters'>
            {
                inputs.map((input, index) =>
                    <div className={`col-lg-${col}`}>
                        <div key={index} className='row align-items-center no-gutters'>
                            <label className='col-lg-3 text-left mb-3 px-1 text-secondary text-capitalize'>
                                {input.text}
                            </label>
                            <div className='col-lg-9 col-12'>
                                <div className='row no-gutters align-items-center'>
                                    {input.inputs.map((input, index) =>
                                        <div key={index} className='col-lg-6'>
                                            {
                                                (input.element === 'input') ?
                                                    <Input bg={'bg-light'} placeHolder={input.text}
                                                           type={input.type}/>
                                                    : <Select bg={'bg-light'}>
                                                        {
                                                            input.options.map((option, index) =>
                                                                <option key={index}
                                                                        value={option.val}>{option.text}</option>
                                                            )
                                                        }
                                                    </Select>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default MapGridTwoInputs;