import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import MapLinkRounded from "../../Helpers/Maps/Map-Link-Rounded";
import {SETTING_NESTED_PATH} from "../../Helpers/Link-Path";

function Groups() {
    const {state: type} = useContext(Localize);
    const colInputsLeft = [
        {
            element: 'input',
            text: Language[type].groupCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].groupName,
            type: 'text'
        }
    ];
    const colInputsRight = [
        {
            element: 'input',
            text: Language[type].groupCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].groupName,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].alternativeGroupDescription,
            type: 'text'
        },

    ];
    const tHead = [
        Language[type].code,
        Language[type].theName,
    ];
    const rows = [
        ['#', '#']
    ];
    const expireBtn = [
        {
            text: Language[type].termsOfReference,
            color: 'bg-dark',
            path: '/' + type + SETTING_NESTED_PATH.groupsExpiresLists,
        }
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].delete,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].edit,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].print,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].groups}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].searchOptions}/>
                        <MapInputs inputs={colInputsLeft}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHead}/>
                            </Thead>
                            <Tbody data={rows}/>
                        </Table>
                        <MapLinkRounded links={expireBtn} />
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputs inputs={colInputsRight}/>
                        <MapBtns btns={buttons} hasMarginTop={false}/>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default Groups;