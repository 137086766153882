export const DASHBOARD_PATH = '/';

export const ROOM_MANAGEMENT_PATH = '/room-management';
export const ROOM_MANAGEMENT_NESTED_PATH = {
    checkIn: ROOM_MANAGEMENT_PATH + '/check-in',
    checkOut: ROOM_MANAGEMENT_PATH + '/check-out',
    rooms: ROOM_MANAGEMENT_PATH + '/rooms'
};

export const RESERVATION_PATH = '/reservations';

export const BONDS_BILLS_PATH = '/bonds-and-bills';
export const BONDS_BILLS_NESTED_PATH = {
    receipt: BONDS_BILLS_PATH + '/receipt',
    services: BONDS_BILLS_PATH + '/services',
    exchangeBills: BONDS_BILLS_PATH + '/exchange-bills',
    exchangeBonds: BONDS_BILLS_PATH + '/exchange-bonds',
    invoices: BONDS_BILLS_PATH + '/invoices'
};
export const CUSTOMERS_PATH = '/customers';
export const SERVICES_PATH = '/services';

export const MESSAGES_PATH = '/messages';
export const MESSAGES_NESTED_PATH = {
    message: MESSAGES_PATH + '/send-message',
    alert: MESSAGES_PATH + '/alert'
};

export const REPORTS_PATH = '/reports';
export const REPORTS_NESTED_PATH = {
    fundMovement: REPORTS_PATH + '/fund-movement',
    exchangeItems: REPORTS_PATH + '/exchange-items',
    trafficReport: REPORTS_PATH + '/daily-traffic',
    monthlyTotal: REPORTS_PATH + 'monthly-total',
    clientReservations: REPORTS_PATH + '/client-reservations',
    bondServices: REPORTS_PATH + '/bond-services',
    openReservations: REPORTS_PATH + '/open-reservations',
    receipt: REPORTS_PATH + '/receipt',
    messages: REPORTS_PATH + '/messages',
    changeRooms: REPORTS_PATH + '/change-rooms',
    rates: REPORTS_PATH + '/rates',
    yearlyMonths: REPORTS_PATH + '/yearly-in-months',
    taxesFees: REPORTS_PATH + '/taxes-and-fees',
    tourismAuthority: REPORTS_PATH + '/tourism-authority',
    bills: REPORTS_PATH + '/bills',
    staffStatistics: REPORTS_PATH + '/staff-statistics',

};
export const SETTING_PATH = '/setting';
export const SETTING_NESTED_PATH = {
    hotelData: SETTING_PATH + '/hotel-data',
    termsContract: SETTING_PATH + '/contract-terms',
    businessSourcesWebsites: SETTING_PATH + '/business-sources-and-websites',
    markets: SETTING_PATH + '/markets',
    budget: SETTING_PATH + '/markets/budget',
    regionsAndStates: SETTING_PATH + '/regions-and-states',
    reasonsForTheVisit: SETTING_PATH + '/reasons-for-the-visit',
    documentType: SETTING_PATH + '/document-type',
    outOfOrder: SETTING_PATH + '/out-of-order',
    typesOfReservations: SETTING_PATH + '/types-of-reservations',
    arrivalMethod: SETTING_PATH + '/arrival-method',
    hotelCodes: SETTING_PATH + '/hotel-codes',
    properties: SETTING_PATH + '/properties',
    roomTypes: SETTING_PATH + '/room-types',
    rooms: SETTING_PATH + '/rooms',
    furniture: SETTING_PATH + '/furniture',
    connectingRoomsList: SETTING_PATH + '/connecting-rooms-list',
    floorPlanDesign: SETTING_PATH + '/floor-plan-design',
    weekend: SETTING_PATH + '/weekend',
    customerRating: SETTING_PATH + '/customer-rating',
    reservations: SETTING_PATH + '/reservations',
    seasonPrices: SETTING_PATH + '/season-prices',
    packages: SETTING_PATH + '/packages',
    priceCategories: SETTING_PATH + '/price-categories',
    priceLists: SETTING_PATH + '/price-lists',
    priceListsPackages: SETTING_PATH + '/price-lists/packages',
    priceListsDetails: SETTING_PATH + '/price-lists/details',
    currency: SETTING_PATH + '/currency',
    accountsGroup: SETTING_PATH + '/accounts-group',
    accountSources: SETTING_PATH + '/account-sources',
    accounts: SETTING_PATH + '/accounts',
    accountsBudget: SETTING_PATH + '/accounts/budget',
    accountants: SETTING_PATH + '/accountants',
    paymentMethods: SETTING_PATH + '/payment-methods',
    taxFeeManagement: SETTING_PATH + '/tax-and-fee-management',
    serviceManagement: SETTING_PATH + '/service-management',
    manageSubscriptions: SETTING_PATH + '/manage-subscriptions',
    connectFrontOffices: SETTING_PATH + '/connect-the-front-offices',
    installBackOfficeLinkage: SETTING_PATH + '/install-the-back-office-linkage',
    doorLock: SETTING_PATH + '/door-lock',
    doorLockComputers: SETTING_PATH + '/door-lock/computers',
    doorLockDevices: SETTING_PATH + '/door-lock/devices',
    doorLockDeviceGroups: SETTING_PATH + '/door-lock/device-groups',
    messages: SETTING_PATH + '/messages',
    roomsInSuns: SETTING_PATH + '/rooms-in-suns',
    sections: SETTING_PATH + '/sections',
    groups: SETTING_PATH + '/groups',
    groupsExpires: SETTING_PATH + '/groups/expires',
    groupsExpiresLists: SETTING_PATH + '/groups/expires/lists',
    groupsExpiresReservations: SETTING_PATH + '/groups/expires/reservations',
    groupsExpiresServices: SETTING_PATH + '/groups/expires/services',
    groupsExpiresAccountants: SETTING_PATH + '/groups/expires/accountants',

    userManagement: SETTING_PATH + '/user-management',
    license: SETTING_PATH + '/license',
    agreement: SETTING_PATH + '/agreement',
    termsConditions: SETTING_PATH + '/terms-and-conditions',
    databaseMaintenance: SETTING_PATH + '/database-maintenance',
    hotelInfo: SETTING_PATH + '/hotel-info',
};