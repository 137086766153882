import React from 'react';

function CardInputs({children}) {
    return (
        <div className='col-lg-6 col-12 mb-3'>
            <div className='rounded w-100 p-4 border h-100'>
                {children}
            </div>
        </div>
    );
}

export default CardInputs;