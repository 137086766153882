function Reducer(state, action) {
    switch (action) {
        case 'ar':
            state = 'ar';
            return state;
        case 'en':
            state = 'en';
            return state;
        default:
            throw new Error();
    }
}

export default Reducer;

export function ShowBool(state, action) {
    return state = action
}