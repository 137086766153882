import React from 'react';

function Select({children, options, bg = 'bg-white'}) {
    return (
        <div className="form-group d-flex align-items-center mb-3 col-lg col-md-12 px-1">
            <select className={`${bg} my-0 rounded-pill text-capitalize form-control`}>
                {children}
                {/*{options.map((item, index) =>*/}
                {/*<option key={index} value={item.val} className="text-capitalize">{item.title}</option>*/}
                {/*)}*/}
            </select>
        </div>
    );
}

export default Select;