import React, {useContext} from 'react';
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import MapMultipleCheckbox from "../../Helpers/Maps/Map-Multiple-Checkbox";
import MapGridTwoInputs from "../../Helpers/Maps/Map-Grid-Two-Inputs";

function HotelData() {
    const {state: type} = useContext(Localize);
    const colInputsLeft = [
        [
            {
                element: 'input',
                text: Language[type].theCompanyName,
                type: 'text'
            },
            {
                element: 'select',
                text: Language[type].country,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'select',
                text: Language[type].district,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'select',
                text: Language[type].title,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'input',
                text: Language[type].address,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].companyPhone,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].fax,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].systemTiming,
                type: 'text'
            }
        ],
        [
            {
                element: 'input',
                text: Language[type].currency,
                type: 'text'
            },
            {
                element: 'multipleCheckboxes',
                text: Language[type].addedValueFivePercent,
                checkboxes: <MapMultipleCheckbox hasMarginBottom={true}
                                                 inputs={[{text: Language[type].taxNumber}, {text: Language[type].taxNumber}]}
                />
            },
            {
                element: 'input',
                text: Language[type].taxNumber,
                type: 'text'
            }
        ],
        [
            {
                text: Language[type].dateOfRegistration,
                inputs: [
                    {
                        element: 'input',
                        text: Language[type].dateOfRegistration,
                        type: 'date'
                    },
                    {
                        element: 'input',
                        text: Language[type].dateOfRegistration,
                        type: 'date'
                    },
                ]
            },
        ]
    ];
    const colInputsRight = [
        {
            element: 'input',
            text: Language[type].companyNameInEnglish,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].eMail,
            type: 'email'
        },
        {
            element: 'input',
            text: Language[type].website,
            type: 'url'
        },
        {
            element: 'input',
            text: Language[type].mailBox,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].postalCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].theLogo,
            type: 'file'
        },
        {
            element: 'input',
            text: Language[type].theMap,
            type: 'file'
        },
        {
            element: 'input',
            text: Language[type].date,
            type: 'date'
        },
        {
            element: 'input',
            text: Language[type].time,
            type: 'date'
        },
    ];
    const btnCol = [
        {
            text: Language[type].timeDateSyncWithDevice,
            color: 'bg-dark',
            onClick: '',
        }]
    const buttons = [
        {
            text: Language[type].save,
            onClick: '',
            color: 'bg-primary'
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].hotelDataSettings}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-6'>
                        <MapInputs inputs={colInputsLeft[0]}/>
                        <div className='row no-gutters'>
                            <div className='col-lg-3'></div>
                            <div className='col-lg-9'>
                                <MapBtns btns={btnCol} hasMarginTop={false}/>
                            </div>
                        </div>
                        <MapInputs inputs={colInputsLeft[1]}/>
                        <MapGridTwoInputs col={12} inputs={colInputsLeft[2]}/>
                    </div>
                    <div className='col-md-6'>
                        <MapInputs inputs={colInputsRight}/>
                    </div>
                </div>
                <div className='row justify-content-end no-gutters'>
                    <MapBtns hasMarginTop={false} btns={buttons}/>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default HotelData;