import * as React from 'react';

export function Logo() {
    return (
        <div className="menu-logo mr-auto d-flex">
            <a className="d-block d-xl-none text-white">
                <i className="icofont-navigation-menu"></i>
            </a><p className="text-white m-0 px-2">
            <strong>FUNDUQI</strong>
        </p>
        </div>
    );
};