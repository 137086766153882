import React from 'react';
import {CheckBox} from "../../Components/Inputs/CheckBox";
import {TextArea} from "../../Components/Inputs/TextArea";
import {Input} from "../../Components/Inputs/Input";
import Select from "../../Components/Inputs/Select";

function MapInputGrids({inputs}) {

    return (
        <>
            <div className='row align-items-center no-gutters'>
                {inputs.map((input, index) =>
                    <div key={index} className='col-lg-6'>
                        <div className='row align-items-center no-gutters'>
                            {
                                (input.element === 'checkbox') ?
                                    <CheckBox labelText={input.text}/>
                                    : <>
                                        <label
                                            className='col-lg-6 text-left mb-3 px-1 text-secondary text-capitalize'>
                                            {input.text}
                                        </label>
                                        {(input.element === 'multipleCheckboxes') ?
                                            input.checkboxes
                                            : (input.element === 'input') ?
                                                <Input bg={'bg-light'} placeHolder={input.text} type={input.type}/>
                                                : <Select bg={'bg-light'}>
                                                    {
                                                        input.options.map((option, index) =>
                                                            <option key={index}
                                                                    value={option.val}>{option.text}</option>
                                                        )
                                                    }
                                                </Select>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default MapInputGrids;