import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import {RoundedBtn} from "../../Components/Buttons/Rounded-Btn";

function DatabaseMaintenance() {
    const {state: type} = useContext(Localize);
    const buttons = [
        {
            text: Language[type].databaseMaintenance,
            color: 'bg-success',
            onClick: '',
        },

        {
            text: Language[type].rebuildSqlEntities,
            color: 'bg-orange',
            onClick: '',
        },
        {
            text: Language[type].correctStatisticsForMonthlyYearlyNightProofingFiles,
            color: 'bg-primary',
            onClick: '',
        },
        {
            text: Language[type].purgeJornalPostingsOlderThanYears,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].purgeFoliosOlderThanYears,
            color: 'bg-purple',
            onClick: '',
        },
        {
            text: Language[type].fixCreditCardEncryption,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].exit,
            selection: true
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].databaseMaintenance}/>
            <ContentSectionTable>
                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        {
                            buttons.map((btn, index) =>
                                <RoundedBtn key={index} text={btn.text}
                                            color={btn.color}
                                            isFormSectionBtn={btn.selection}
                                />
                            )
                        }
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default DatabaseMaintenance;