import * as React from 'react';

export function Pagination(props) {
    return (
        <div className="btn-group mx-auto ml-md-auto mr-md-0">
            <div className="btn-group ml-auto">
                <button className="btn btn-secondary bg-normal-black text-capitalize">first</button>
                <button className="btn btn-secondary bg-normal-black text-capitalize">previous
                </button>
                <button className="btn btn-secondary bg-light-black text-capitalize">1</button>
                <button className="btn btn-secondary bg-normal-black text-capitalize">next</button>
                <button className="btn btn-secondary bg-normal-black text-capitalize">last</button>
            </div>
        </div>
    );
};