import React from 'react';

function Table({children}) {
    return (
        <div className="overflow-auto">
            <table className="table table-bordered border-white m-0">
                {children}
            </table>
        </div>
    );
}

export default Table;