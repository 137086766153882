import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputGrids from "../../Helpers/Maps/Map-Input-Grids";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import MapMultipleCheckbox from "../../Helpers/Maps/Map-Multiple-Checkbox";

function Rooms() {
    const {state: type} = useContext(Localize);
    const colInputsLeft = [
        {
            element: 'input',
            text: Language[type].theNumber,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].description,
            type: 'text'
        }
    ];
    const colInputsRight = [
        [
            {
                element: 'checkbox',
                text: Language[type].active,
            },
            {
                element: 'input',
                text: Language[type].theNumber,
                type: 'text'
            },
            {
                element: 'select',
                text: Language[type].theType,
                options: [
                    {
                        val: '',
                        text: 'Choose'
                    }
                ]
            },
            {
                element: 'input',
                text: Language[type].description,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].alternateDescription,
                type: 'text'
            },
        ],
        [
            {
                element: 'input',
                text: Language[type].case,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].roomService,
                type: 'text'
            }
        ],
        [
            {
                element: 'multipleCheckboxes',
                text: '',
                checkboxes: <MapMultipleCheckbox hasMarginBottom={true} inputs={[
                    {
                        text: Language[type].telephoneGuide,
                    },
                    {
                        text: Language[type].theNewspaper,
                    },
                    {
                        text: Language[type].qiblaDetermination,
                    },
                    {
                        text: Language[type].foodMenu,
                    },
                    {
                        text: Language[type].refrigerator,
                    },
                    {
                        text: Language[type].hall,
                    },
                ]}/>
            },
            {
                element: 'input',
                text: Language[type].notes,
                type: 'text'

            },
            {
                element: 'checkbox',
                text: Language[type].allowManualPosting,
            },
            {
                element: 'checkbox',
                text: Language[type].allowTransferFrom,
            },
            {
                element: 'checkbox',
                text: Language[type].allowTransferTo,
            },
        ],
        [
            {
                element: 'input',
                text: Language[type].theRooms,
                type: 'text'
            },
            {
                element: 'input',
                text: Language[type].maximumNumberOfRooms,
                type: 'text'
            }
        ],
    ];
    const tHead = [
        Language[type].number,
        Language[type].theType,
        Language[type].description
    ];
    const rows = [
        ['#', '#', '#']
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].delete,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].edit,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].print,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].theRooms}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].searchOptions}/>
                        <MapInputs inputs={colInputsLeft}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHead}/>
                            </Thead>
                            <Tbody data={rows}/>
                        </Table>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputs inputs={colInputsRight[0]}/>
                        <MapInputGrids inputs={colInputsRight[1]}/>
                        <MapInputs inputs={colInputsRight[2]}/>
                        <div className='w-100 mt-3'>
                            <MapInputGrids inputs={colInputsRight[3]}/>
                        </div>
                        <MapBtns btns={buttons}/>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );

}

export default Rooms;