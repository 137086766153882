import * as React from 'react';
import {Result} from "../Components/Texts/Result";
import {Pagination} from "../Components/Pagination/Pagination";
import {RoundedBtn} from "../Components/Buttons/Rounded-Btn";

export function ContentSectionFooter({resultText, paginationNum}) {
    return (
        <>
            <div className="mt-5 d-flex w-100 align-items-center flex-column flex-md-row">
                <Result text={resultText}/>
                <Pagination/>
            </div>

        </>
    );
};