import React, {Component} from 'react';
import {TitleCenter} from "../../Components/Titles/Title-Center";
import {ContentForm} from "../../Templates/Content_Form";
import {Input} from "../../Components/Inputs/Input";
import Select from "../../Components/Inputs/Select";
import {RoundedBtn} from "../../Components/Buttons/Rounded-Btn";
import {CheckBox} from "../../Components/Inputs/CheckBox";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import SectionHeadBtn from "../../Components/Buttons/Section-Head-Btn";
import {ContentSection} from "../../Templates/Content_Section";
import Thead from "../../Components/Tables/Thead";
import Tbody from "../../Components/Tables/Tbody";
import TrThead from "../../Components/Tables/Tr-Thead";
import Table from "../../Components/Tables/Table";
import {ContentSectionFooter} from "../../Templates/Content_Section_Footer";
import MapFormFlexFill from "../../Helpers/Maps/Map-Form-Flex-Fill";

class Customers extends Component {
    render() {
        const form = [
            {
                tagType: 'input',
                inputType: 'text',
                placeHolder: 'Evidance Name',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'input',
                inputType: 'text',
                placeHolder: 'Name',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'input',
                inputType: 'number',
                placeHolder: 'Phone Number',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Choose Category',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            }

        ];
        const tHead = [
            'Name',
            'Evidence Type',
            'Evidence Number',
            'Version Release',
            'Phone',
            'E-Mail Address',
            'Operations'
        ];
        const rows = [
            ['#', '#', '#', '#', '#', '#', '#'],
            ['#', '#', '#', '#', '#', '#', '#']
        ];
        return (
            <>
                <TitleCenter title={'customers'} icon={'user-suited'}/>
                <ContentForm marginTop={'mt-5'}>
                    <MapFormFlexFill formInputs={form} />
                    <RoundedBtn text={'Search'} flexFill={false} />
                    <RoundedBtn text={'Export'} flexFill={false} color={'bg-light-blue'}/>
                    <div className="w-100 px-2 text-left">
                        <CheckBox labelText={'Show Companies'}/>
                    </div>
                </ContentForm>
                <ContentSection marginTop={'mt-4'}>
                    <ContentSectionHead title={'Customer Management'}>
                        <SectionHeadBtn btnText={'Add Customers'} btnIcon={'ui-add'}/>
                    </ContentSectionHead>
                    <ContentSectionTable>
                        <Table>
                            <Thead>
                            <TrThead data={tHead}/>
                            </Thead>
                            <Tbody data={rows}/>
                        </Table>
                        <ContentSectionFooter resultText={'Results 1-8 of 8'}/>
                    </ContentSectionTable>
                </ContentSection>
            </>
        );
    }
}

export default Customers;