import React from 'react';
import VerticalList from "../Lists/Vertical-List";

function CardSetting({bgColor, text, list}) {
    return (
        <div className="col-lg-3 col-md-6 col-12 px-2 pb-4">
            <div className="w-100 h-100 dashboard-case bg-white">
                <div className={`section-table-title row w-100 p-3 align-items-center no-gutters ${bgColor}`}>
                    <p className="text-center text-lg-left text-capitalize m-0 text-white col-md-12 col-lg-auto">
                        {text}
                    </p>
                </div>
                <div className="w-100 bg-white py-3 section-table-container overflow-hidden">
                    <div className="w-100 h-100 dashboard-case bg-white">
                        <VerticalList lists={list}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardSetting;