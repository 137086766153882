import React from 'react';
import {CheckBox} from "../../Components/Inputs/CheckBox";

function MapMultipleCheckbox({inputs, hasMarginBottom = false}) {
    return (
        <div className={`text-left ${hasMarginBottom && 'mb-3'}`}>
            {inputs.map(checkbox =>
                <CheckBox labelText={checkbox.text}/>
            )}
        </div>
    );
}

export default MapMultipleCheckbox;