import React, {Component} from 'react';
import {TitleLeft} from "../../Components/Titles/Title-Left";
import {ContentForm} from "../../Templates/Content_Form";
import {Input} from "../../Components/Inputs/Input";
import Select from "../../Components/Inputs/Select";
import {RoundedBtn} from "../../Components/Buttons/Rounded-Btn";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import Tbody from "../../Components/Tables/Tbody";
import {ContentSectionFooter} from "../../Templates/Content_Section_Footer";
import TrThead from "../../Components/Tables/Tr-Thead";
import SectionHeadBtn from "../../Components/Buttons/Section-Head-Btn";

class Reservations extends Component {
    render() {
        const form = [
            {
                tagType: 'input',
                inputType: 'date',
                placeHolder: 'start date',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'input',
                inputType: 'date',
                placeHolder: 'End Date',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Choose Room',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
            {
                tagType: 'input',
                inputType: 'text',
                placeHolder: 'Room',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'input',
                inputType: 'number',
                placeHolder: 'Reservation Number',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Room Type',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Reservation Source',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
            {
                tagType: 'input',
                inputType: 'number',
                placeHolder: 'Reservation Source Number',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'input',
                inputType: 'text',
                placeHolder: 'Client Name',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'input',
                inputType: 'number',
                placeHolder: 'Phone Number',
                onChange: '',
                onFocus: '',
                onBlur: ''
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Rent Type',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Reservation Type',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
            {
                tagType: 'select',
                onChange: '',
                onFocus: '',
                onBlur: '',
                options: [
                    {
                        text: 'Open Reservation',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                    {
                        text: '',
                        val: ''
                    },
                ]
            },
        ];
        const tHeadOne = [
            {
                col: 5,
                text: ''
            },
            {
                col: 5,
                text: 'Reservation'
            },
            {
                col: 3,
                text: 'required'
            },
            {
                col: 3,
                text: 'financial'
            },
            {
                col: 1,
                text: ''
            }
        ];
        const tHeadTwo = [
            'Reservation Number',
            'Reference',
            'Reservation Status',
            'Room',
            'Client',
            'Reservation Type',
            'Check In',
            'Check Out',
            'Duration',
            'Rent',
            'Amount',
            'Tax',
            'Total',
            'Paid',
            'Creditor',
            'Debit',
            'Operations'
        ];
        const rows = [
            ['#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#'],
            ['#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#'],

        ];
        return (
            <>
                <TitleLeft text={'advanced search'}/>
                <ContentForm>
                    {form.map((elm, index) =>
                        <div key={index} className="col-lg-3 col-md-6 col-12">
                            {
                                (elm.tagType === 'input') ?
                                    <Input type={elm.inputType} placeHolder={elm.placeHolder}/>
                                    :
                                    <Select>
                                        {
                                            elm.options.map((option, index) =>
                                                <option key={index} value={option.val}>{option.text}</option>
                                            )
                                        }
                                    </Select>
                            }
                        </div>
                    )}
                    <RoundedBtn text={'Search'} flexFill={false}/>
                    <RoundedBtn text={'Export'} flexFill={false} color={'bg-light-blue'}/>
                </ContentForm>
                <ContentSection marginTop={'mt-4'}>
                    <ContentSectionHead
                        title={'Reservations'}
                    >
                        <SectionHeadBtn
                            btnIcon={'ui-add'} btnClass={'mr-sm-2'} btnText={'add Reservation'}
                        />
                        <SectionHeadBtn
                            btnIcon={'users'} btnText={'add reservation group'} hasLeftAuto={false}
                        />
                    </ContentSectionHead>
                    <ContentSectionTable>
                        <Table>
                            <Thead>
                            <TrThead data={tHeadOne}/>
                            <TrThead data={tHeadTwo}/>
                            </Thead>
                            <Tbody data={rows}/>
                        </Table>
                        <ContentSectionFooter resultText={'Results 1-8 of 8'}/>
                    </ContentSectionTable>
                </ContentSection>

            </>
        );
    }
}

export default Reservations;