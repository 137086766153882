import * as React from 'react';

export function NotifyList({color, num, text}) {
    return (
        <li className="nav-item text-left px-2 pb-2 pb-xl-0">
            <div className="d-flex p-0 align-items-center">
                <div className={`col-auto rounded-pill text-white text-center p-0 d-flex align-items-center ${color}`}>
                    <span className="w-100">{num}</span></div>
                <span className="mx-1 my-0 py-0">{text}</span>
            </div>
        </li>
    );
};