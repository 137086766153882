import React from 'react';
import {CheckBox} from "../../Components/Inputs/CheckBox";
import {Input} from "../../Components/Inputs/Input";
import Select from "../../Components/Inputs/Select";
import {TextArea} from "../../Components/Inputs/TextArea";

function MapInputs({inputs, flexfill= false}) {
    return (
        <>
            {inputs.map((input, index) =>
                <div key={index} className={`row align-items-center no-gutters ${flexfill && 'flex-fill'}`}>
                    {
                        (input.element === 'checkbox') ?
                            <CheckBox labelText={input.text}/>
                            : (input.element === 'textarea') ?
                            <TextArea text={input.text} bg={'bg-light'} row={input.row} placeHolder={input.text}/>
                            : <>
                                <label
                                    className='col-lg-3 text-left mb-3 px-1 text-secondary text-capitalize'>
                                    {input.text}
                                </label>
                                {(input.element === 'textareaLabel') ?
                                    <TextArea text={input.text} bg={'bg-light'} row={input.row}
                                              placeHolder={input.text}/>
                                    : (input.element === 'multipleCheckboxes') ?
                                        input.checkboxes
                                        : (input.element === 'input') ?
                                            <Input bg={'bg-light'} placeHolder={input.text} type={input.type}/>
                                            : <Select bg={'bg-light'}>
                                                {
                                                    input.options.map((option, index) =>
                                                        <option key={index}
                                                                value={option.val}>{option.text}</option>
                                                    )
                                                }
                                            </Select>
                                }
                            </>
                    }
                </div>
            )}
        </>
    );
}

export default MapInputs;