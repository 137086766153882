import React, {useContext} from 'react';
import {TitleCenter} from "../../Components/Titles/Title-Center";
import {SETTING_NESTED_PATH} from "../../Helpers/Link-Path";
import CardSetting from "../../Components/Cards/Card-Setting";
import {Language} from "../../Lang/Language";
import Localize from "../../Helpers/Context";
import {ContentSection} from "../../Templates/Content_Section";

export function Settings() {
    const {state: type} = useContext(Localize);
    const cards = [
        {
            cardTitle: Language[type].generalSettings,
            cardColor: 'bg-primary',
            list: [
                {
                    text: Language[type].hotelDataSettings,
                    link: '/' + type + SETTING_NESTED_PATH.hotelData
                },
                {
                    text: Language[type].contractTermsSettings,
                    link: '/' + type + SETTING_NESTED_PATH.termsContract
                },
                {
                    text: Language[type].businessSourcesAndWebsites,
                    link: '/' + type + SETTING_NESTED_PATH.businessSourcesWebsites
                },
                {
                    text: Language[type].markets,
                    link: '/' + type + SETTING_NESTED_PATH.markets
                },
                {
                    text: Language[type].regionsAndStates,
                    link: '/' + type + SETTING_NESTED_PATH.regionsAndStates
                },
                {
                    text: Language[type].reasonsForTheVisit,
                    link: '/' + type + SETTING_NESTED_PATH.reasonsForTheVisit
                },
                {
                    text: Language[type].documentType,
                    link: '/' + type + SETTING_NESTED_PATH.documentType
                },
                {
                    text: Language[type].outOfOrder,
                    link: '/' + type + SETTING_NESTED_PATH.outOfOrder
                },
                {
                    text: Language[type].typesOfReservations,
                    link: '/' + type + SETTING_NESTED_PATH.typesOfReservations
                },
                {
                    text: Language[type].arrivalMethod,
                    link: '/' + type + SETTING_NESTED_PATH.arrivalMethod
                }
            ]
        },
        {
            cardTitle: Language[type].theRooms,
            cardColor: 'bg-rose',
            list: [
                {
                    text: Language[type].hotelCodes,
                    link:  '/' + type + SETTING_NESTED_PATH.hotelCodes
                },
                {
                    text: Language[type].theProperties,
                    link:  '/' + type + SETTING_NESTED_PATH.properties
                },
                {
                    text: Language[type].roomTypes,
                    link:  '/' + type + SETTING_NESTED_PATH.roomTypes
                },
                {
                    text: Language[type].theRooms,
                    link:  '/' + type + SETTING_NESTED_PATH.rooms
                },
                {
                    text: Language[type].furniture,
                    link:  '/' + type + SETTING_NESTED_PATH.furniture
                },
                {
                    text: Language[type].connectingRoomsList,
                    link:  '/' + type + SETTING_NESTED_PATH.connectingRoomsList
                },
                {
                    text: Language[type].floorPlanDesign,
                    link:  '/' + type + SETTING_NESTED_PATH.floorPlanDesign
                }
            ]
        },
        {
            cardTitle: Language[type].theSales,
            cardColor: 'bg-green',
            list: [
                {
                    text: Language[type].weekend,
                    link: '/' + type + SETTING_NESTED_PATH.weekend
                },
                {
                    text: Language[type].customerRating,
                    link: '/' + type + SETTING_NESTED_PATH.customerRating
                },
                {
                    text: Language[type].reservationsSettings,
                    link: '/' + type + SETTING_NESTED_PATH.reservations
                },
            ]
        },
        {
            cardTitle: Language[type].thePrices,
            cardColor: 'bg-nile',
            list: [
                {
                    text: Language[type].seasonSeasons,
                    link: '/' + type + SETTING_NESTED_PATH.seasonPrices
                },
                {
                    text: Language[type].priceCategories,
                    link: '/' + type + SETTING_NESTED_PATH.priceCategories
                },
                {
                    text: Language[type].packages,
                    link: '/' + type + SETTING_NESTED_PATH.packages
                },
                {
                    text: Language[type].priceLists,
                    link: '/' + type + SETTING_NESTED_PATH.priceLists
                },
                {
                    text: Language[type].theCurrency,
                    link: '/' + type + SETTING_NESTED_PATH.currency
                },

            ]
        },
        {
            cardTitle: Language[type].accounting,
            cardColor: 'bg-purple',
            list: [
                {
                    text: Language[type].accountsGroup,
                    link: '/' + type + SETTING_NESTED_PATH.accountsGroup
                },
                {
                    text: Language[type].accountSources,
                    link: '/' + type + SETTING_NESTED_PATH.accountSources
                },
                {
                    text: Language[type].accounts,
                    link: '/' + type + SETTING_NESTED_PATH.accounts
                },
                {
                    text: Language[type].accountantsSettings,
                    link: '/' + type + SETTING_NESTED_PATH.accountants
                },
                {
                    text: Language[type].paymentMethods,
                    link: '/' + type + SETTING_NESTED_PATH.paymentMethods
                },
                {
                    text: Language[type].taxAndFeeManagement,
                    link: '/' + type + SETTING_NESTED_PATH.taxFeeManagement
                },
                {
                    text: Language[type].serviceManagement,
                    link: '/' + type + SETTING_NESTED_PATH.serviceManagement
                },
            ]
        },
        {
            cardTitle: Language[type].connectivity,
            cardColor: 'bg-secondary',
            list: [
                {
                    text: Language[type].manageSubscriptions,
                    link: '/' + type + SETTING_NESTED_PATH.manageSubscriptions
                },
                {
                    text: Language[type].connectTheFrontOffices,
                    link: '/' + type + SETTING_NESTED_PATH.connectFrontOffices
                },
                {
                    text: Language[type].installTheBackOfficeLinkage,
                    link: '/' + type + SETTING_NESTED_PATH.installBackOfficeLinkage
                },
                {
                    text: Language[type].doorLock,
                    link: '/' + type + SETTING_NESTED_PATH.doorLockComputers
                },
                {
                    text: Language[type].messages,
                    link: '/' + type + SETTING_NESTED_PATH.messages
                },
                {
                    text: Language[type].theRoomsAreInSuns,
                    link: '/' + type + SETTING_NESTED_PATH.roomsInSuns
                },
            ]
        },
        {
            cardTitle: Language[type].protection,
            cardColor: 'bg-red',
            list: [
                {
                    text: Language[type].sections,
                    link: '/' + type + SETTING_NESTED_PATH.sections
                },
                {
                    text: Language[type].groups,
                    link: '/' + type + SETTING_NESTED_PATH.groups
                },
                {
                    text: Language[type].userManagement,
                    link: '/' + type + SETTING_NESTED_PATH.userManagement
                },
            ]
        },
        {
            cardTitle: Language[type].theSystem,
            cardColor: 'bg-orange',
            list: [
                {
                    text: Language[type].theLicense,
                    link: '/' + type + SETTING_NESTED_PATH.license
                },
                {
                    text: Language[type].theAgreement,
                    link: '/' + type + SETTING_NESTED_PATH.agreement
                },
                {
                    text: Language[type].termsAndConditions,
                    link: '/' + type + SETTING_NESTED_PATH.termsConditions
                },
                {
                    text: Language[type].databaseMaintenance,
                    link: '/' + type + SETTING_NESTED_PATH.databaseMaintenance
                },
                {
                    text: Language[type].aboutMyHotel,
                    link: '/' + type + SETTING_NESTED_PATH.hotelInfo
                }
            ]
        }
    ];
    return (
        <>
            <TitleCenter title={Language[type].settings} icon={'settings'}/>
            <ContentSection>
                <div className="row px-3 align-items-stretch">
                    {cards.map((card, index) =>
                        <CardSetting
                            key={index}
                            text={card.cardTitle}
                            bgColor={card.cardColor}
                            list={card.list}
                        />
                    )}
                </div>
            </ContentSection>
        </>
    );
};