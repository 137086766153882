import * as React from 'react';

export function CircleTextBottom({imgUrl, text}) {
    return (
        <li className="nav-item text-center mx-2 mb-3 mb-lg-0">
            <a className="nav-link text-capitalize text-dark py-0" href="#">
                <div className="section-circle p-4 mx-auto rounded-pill bg-normal-black">
                    <img className="h-100 w-100" src={`assets/img/${imgUrl}.png`} />
                </div>
                <span className="h5 pt-2 d-block m-0 pb-0">{text}</span>
            </a>
        </li>
    );
};