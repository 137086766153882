import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import TableIconList from "../../Components/Lists/Table-Icon-List";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function RoomsInSuns() {
    const {state: type} = useContext(Localize);
    const tHead = [
        Language[type].theRoomsAreInSuns,
        Language[type].roomNumberInSuns
    ];
    const rows = [
        ['#', '#']
    ];
    const buttons = [
        {
            text: Language[type].save,
            color: 'bg-primary',
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].theRoomsAreInSuns}/>
            <ContentSectionTable>
                <Table>
                    <Thead>
                        <TrThead data={tHead}/>
                    </Thead>
                    <Tbody data={rows}/>
                </Table>
                <div className='d-flex justify-content-end w-100'>
                    <MapBtns btns={buttons} flexFill={false}/>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default RoomsInSuns;