import React, {useContext, useEffect} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import MapLinkRounded from "../../Helpers/Maps/Map-Link-Rounded";
import DoorLockComputers from "./Door-Lock-Computers";
import DoorLockDevices from "./Door-Lock-Devices";
import DoorLockDeviceGroups from "./Door-Lock-Device-Groups";
import {SETTING_NESTED_PATH} from "../../Helpers/Link-Path";

function DoorLock({match}) {
    const {state: type} = useContext(Localize);
    const tabButtons = [
        {
            text: Language[type].computers,
            color: 'bg-secondary',
            path: '/' + type + SETTING_NESTED_PATH.doorLockComputers,
        },
        {
            text: Language[type].devices,
            color: 'bg-secondary',
            path: '/' + type + SETTING_NESTED_PATH.doorLockDevices,
        },
        {
            text: Language[type].deviceGroups,
            color: 'bg-secondary',
            path: '/' + type + SETTING_NESTED_PATH.doorLockDeviceGroups,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].doorLock}/>
            <ContentSectionTable>
                <MapLinkRounded links={tabButtons} justify={'center'} hasMarginTop={false}/>
                <Switch>
                    <Route exact path={match.url + '/computers'} component={DoorLockComputers}/>
                    <Route path={match.url + '/devices'} component={DoorLockDevices}/>
                    <Route path={match.url + '/device-groups'} component={DoorLockDeviceGroups}/>
                </Switch>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default DoorLock;