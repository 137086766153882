import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function OutOfOrder() {
    const {state: type} = useContext(Localize);
    const colInputsLeft = [
        {
            element: 'input',
            text: Language[type].code,
            type: 'text'
        },

    ];
    const colInputsRight = [

        {
            element: 'input',
            text: Language[type].visitCode,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].description,
            type: 'text'
        },
        {
            element: 'input',
            text: Language[type].alternateDescription,
            type: 'text'
        },

    ];
    const tHead = [
        Language[type].code,
        Language[type].description,
    ];
    const rows = [
        ['#', '#']
    ];
    const buttons = [
        {
            text: Language[type].add,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].delete,
            color: 'bg-secondary',
            onClick: '',
        },
        {
            text: Language[type].edit,
            color: 'bg-dark',
            onClick: '',
        },
        {
            text: Language[type].print,
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].outOfOrder}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].sortAndSearch}/>
                        <MapInputs inputs={colInputsLeft}/>
                        <Table>
                            <Thead>
                                <TrThead data={tHead}/>
                            </Thead>
                            <Tbody data={rows}/>
                        </Table>
                    </div>
                    <div className='col-md-6'>
                        <TitleContent text={Language[type].theDetails}/>
                        <MapInputs inputs={colInputsRight}/>
                        <MapBtns btns={buttons}/>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default OutOfOrder;