import React from 'react';

function ContentSectionHead({children, title, titleHaveImg = false, titleImg}) {
    return (
        <div
            className="section-table-title row w-100 bg-normal-black px-5 py-3 align-items-center no-gutters">
            <h4 className="text-center text-lg-left text-capitalize m-0 text-white col-md-12 col-lg-auto mb-2 mb-lg-0">
                {titleHaveImg && <img className="mr-2" src={`assets/img/${titleImg}.png`}/>}{title}</h4>
            {children}
        </div>
    );
}

export default ContentSectionHead;