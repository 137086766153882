import React from 'react';
import {CircleTextLeft} from "../../Components/Links/Circle-Text-Left";

function MapCircleLinkTextLeft({list}) {
    return (
        <>
            {list.map((item, index) =>
                <CircleTextLeft key={index} componentPath={item.path} text={item.text} imgUrl={item.imgUrl}
                                circleColor={item.color}
                />
            )}
        </>
    );
}

export default MapCircleLinkTextLeft;