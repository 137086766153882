import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import BackLink from "../../Components/Buttons/Back-Link";

function PricePackages() {
    const {state: type} = useContext(Localize);
    const colInputsLeft = [
        {
            element: 'input',
            text: Language[type].priceSymbol,
            type: 'text'
        },
        {
            element: 'checkbox',
            text: Language[type].ChooseAll,
        },
        {
            element: 'checkbox',
            text: 'BF Breakfast Buffer',
        },
        {
            element: 'checkbox',
            text: 'LU Lunch Buffer',
        },
        {
            element: 'checkbox',
            text: 'DI Dinner Buffer',
        },
        {
            element: 'checkbox',
            text: 'RIAS Ramadan Iftar Asian',
        },
        {
            element: 'checkbox',
            text: 'RIAR Ramadan Iftar Arab',
        },
        {
            element: 'checkbox',
            text: 'RSAS Ramadan Sohur Asian',
        },
        {
            element: 'checkbox',
            text: 'RSAR Ramadan Sohur Arab',
        },
        {
            element: 'checkbox',
            text: 'BFG Breakfast Group',
        },
        {
            element: 'checkbox',
            text: 'LUG Lunch Group',
        },
        {
            element: 'checkbox',
            text: 'DIG Dinner Group',
        },
    ];
    const buttons = [
        {
            text: Language[type].done,
            color: 'bg-success',
            onClick: '',
        },
        {
            text: Language[type].cancel,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].pricePackages}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-6'>
                        <BackLink text={Language[type].backToPriceLists}/>
                        <MapInputs inputs={colInputsLeft}/>
                        <MapBtns btns={buttons}/>
                    </div>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default PricePackages;