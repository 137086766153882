import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import Table from "../../Components/Tables/Table";
import Thead from "../../Components/Tables/Thead";
import TrThead from "../../Components/Tables/Tr-Thead";
import Tbody from "../../Components/Tables/Tbody";
import MapBtns from "../../Helpers/Maps/Map-Btns";

function Furniture() {
    const {state: type} = useContext(Localize);
    const tHead = [
        Language[type].theName,
        Language[type].price,
        Language[type].case,
        Language[type].operations
    ];
    const rows = [
        ['#', '#', '#']
    ];
    const buttons = [
        {
            text: Language[type].export,
            color: 'bg-primary',
            onClick: '',
        },
        {
            text: Language[type].exportAll,
            color: 'bg-light-blue',
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    const operationBtns = [
        {
            func: '',
            icon: 'search-document'
        },
        {
            func: '',
            icon: 'ui-edit'
        },
        {
            func: '',
            icon: 'close-circled'
        }
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].furniture}/>
            <ContentSectionTable>
                <Table>
                    <Thead>
                        <TrThead data={tHead}/>
                    </Thead>
                    <Tbody data={rows} hasOperation={true} buttons={operationBtns}/>
                </Table>
                <MapBtns btns={buttons} flexFill={false}/>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default Furniture;