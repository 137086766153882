import React, {useContext} from 'react';
import Localize from "../../Helpers/Context";
import {Language} from "../../Lang/Language";
import MapMultipleCheckbox from "../../Helpers/Maps/Map-Multiple-Checkbox";
import {ContentSection} from "../../Templates/Content_Section";
import ContentSectionHead from "../../Templates/Content_Section_Head";
import ContentSectionTable from "../../Templates/Content_Section_Table";
import TitleContent from "../../Components/Titles/Title-Content";
import MapInputs from "../../Helpers/Maps/Map-Inputs";
import MapInputGrids from "../../Helpers/Maps/Map-Input-Grids";
import MapBtns from "../../Helpers/Maps/Map-Btns";
import TextNote from "../../Components/Texts/Text-Note";

function Reservations() {
    const {state: type} = useContext(Localize);
    const colInputsLeft = [
        [
            {
                element: 'checkbox',
                text: Language[type].active,
            }
        ],
        [
            {
                element: 'select',
                text: Language[type].hour,
                options: [
                    {
                        val: '',
                        text: 'choose'
                    }
                ]
            },
            {
                element: 'select',
                text: Language[type].TheTiming,
                options: [
                    {
                        val: '',
                        text: 'AM'
                    },
                    {
                        val: '',
                        text: 'PM'
                    },
                ]
            },
        ],
        [
            {
                element: 'checkbox',
                text: Language[type].gracePeriodForChangingRooms,
            }
        ],
        [
            {
                element: 'select',
                text: Language[type].hour,
                options: [
                    {
                        val: '',
                        text: 'choose'
                    }
                ]
            }
        ],
        [
            {
                element: 'multipleCheckboxes',
                text: '',
                checkboxes: <MapMultipleCheckbox hasMarginBottom={true} inputs={[
                    {
                        text: Language[type].enteringTheRoomIsObligatoryInTheNewContract,
                    },
                    {
                        text: Language[type].activateCheckoutTimeForMonthlyReservations,
                    },
                    {
                        text: Language[type].whenChangingTheRoomCalculateThePriceOfTheNewApartment,
                    }
                ]}/>
            }
        ],
    ];
    const colInputsRight = [
        [
            {
                element: 'checkbox',
                text: Language[type].active,
            }
        ],
        [
            {
                element: 'select',
                text: Language[type].hour,
                options: [
                    {
                        val: '',
                        text: 'choose'
                    }
                ]
            },
            {
                element: 'select',
                text: Language[type].TheTiming,
                options: [
                    {
                        val: '',
                        text: 'AM'
                    },
                    {
                        val: '',
                        text: 'PM'
                    },
                ]
            },
        ]
    ];
    const buttons = [
        {
            text: Language[type].save,
            color: 'bg-primary',
            onClick: '',
        },
        {
            text: Language[type].exit,
            onClick: '',
            selection: true,
        },
    ];
    return (
        <ContentSection marginTop={'mt-0'}>
            <ContentSectionHead title={Language[type].reservationsSettings}/>
            <ContentSectionTable>
                <div className='row'>
                    <div className='col-md-6 px-1'>
                        <TitleContent text={Language[type].timeCheckIn}/>
                        <div className='row no-gutters align-items-baseline'>
                            <div className='col-lg-2 px-1'>
                                <MapInputs inputs={colInputsLeft[0]}/>
                            </div>
                            <div className='col-lg-10 px-1'>
                                <MapInputGrids inputs={colInputsLeft[1]}/>
                            </div>
                            <div className='col-lg-4 px-1'>
                                <MapInputs inputs={colInputsLeft[2]}/>
                            </div>
                            <div className='col-lg-8 px-1'>
                                <MapInputs inputs={colInputsLeft[3]}/>
                            </div>
                        </div>
                        <MapInputs inputs={colInputsLeft[4]}/>
                    </div>
                    <div className='col-md-6 px-1'>
                        <TitleContent text={Language[type].timeCheckOut}/>
                        <div className='row no-gutters align-items-baseline'>
                            <div className='col-lg-2'>
                                <MapInputs inputs={colInputsRight[0]}/>
                            </div>
                            <div className='col-lg'>
                                <MapInputGrids inputs={colInputsRight[1]}/>
                            </div>
                        </div>
                    </div>
                    <TextNote text={Language[type].reservationSettingErrOne}/>
                    <TextNote text={Language[type].reservationSettingErrTwo}/>

                </div>
                <div className='row justify-content-end'>
                    <MapBtns btns={buttons}/>
                </div>
            </ContentSectionTable>
        </ContentSection>
    );
}

export default Reservations;