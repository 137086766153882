import * as React from 'react';

export function TitleCenter({icon, title}) {
    return (
        <div className="col-12 text-center align-self-start">
            <h3
                className="h3 d-inline-block mx-auto bg-white py-3 px-4 text-capitalize text-nile m-0">
                <i className={`icofont-${icon}`}></i> {title}</h3>
        </div>
    );
};